// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette(mat.$indigo-palette);
$app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat.define-light-theme($app-primary, $app-accent, $app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-font-family: Barlow, sans-serif;

  --ion-color-primary: #1781FF;
  --ion-color-primary-rgb: 7, 76, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #1472e0;
  --ion-color-primary-tint: #2e8eff;

  --ion-color-secondary: #0107BB;
  --ion-color-secondary-rgb: 0, 2, 162;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #00028f;
  --ion-color-secondary-tint: #1a1bab;


  // used for background
  --ion-color-tertiary: #000000;
  --ion-color-tertiary-rgb: 14, 15, 17;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #0c0d0f;
  --ion-color-tertiary-tint: #262729;

  --ion-color-success: #34D04A;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  --ion-color-danger: #ED1C24;
  --ion-color-danger-rgb: 237, 28, 36;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d11920;
  --ion-color-danger-tint: #ef333a;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  // Custom colors
  --white: #fff;
  --white-opacity-02: rgba(255, 255, 255, 0.2);
  --light: #f4f5f8;
  --light-text: #989aa2;
  --panel: #2A2F49;
  --dark-bg: #000000;
  --delete-color: #DA5C5C; //used for remove and delete buttons
  --delete-color-opacity-15: rgba(218, 92, 92, 0.15);
  --dark-hover: #3e4a67;
  --panel-button: #333b51;
  --panel-button-light: #67CDE6;
  --panel-text: #8189B1;
  --ion-text-color: white;
  --light-green: #1ca24d; // used in slider my subscription
  --light-blue: rgba(0, 194, 255, 1); // used for marker details added bulk gamedat
  --primary-blue: #1781FF; // session list selector selected colour, top performance active bar border colour (Home)
  --primary-blue-opacity-low: rgba(23, 129, 255, 0.30); // background performance active bar (Home)
  --gradient-start: #0B6EFF;
  --gradient-end: #0100FC;
  --non-selected-tab: #4175FF;
  --tab-hover: #a0b6ff;
  --panel-gradient-start: #0000FE;
  --panel-gradient-end: #00058d;
  --panel-dark-gradient-start: #1A202E;
  --panel-dark-gradient-end: #121524;
  --panel-dark: #0E0F11; // Most if not all panels in app header color, navigation (tablet) color, performance cards (home page), session selector popover (home page) (desktop)
  --fourth-colour: #AFAFAF; // used for unselected font used for backgrounds of containers in squads analysis and ellipsis dots in squad analysis page for graph when too many players
  --fourth-colour-opacity-01: rgba(175, 175, 175, 0.10); //border search bar analysis
  --fourth-colour-opacity-20: rgba(175, 175, 175, 0.20);
  --fourth-colour-opacity-003: rgba(255, 255, 255, 0.03); //background search bar analysis
  --midnigth-ink-colour: rgba(36, 36, 36, 1);
  --midnight-onx: rgb(18, 18, 21);
  --urban-charcoal: rgb(36, 36, 36); // color of modal 4 border
  --ds-grey-400: rgba(46, 46, 46, 1); // cancel button border reusable modal structure 4
  --grey-800: #17181C; // used for top of the session selector container, user performance border (home page) send reminder button analysis page
  --grey-800-opacity-low: rgba(23, 24, 28, 0.30); //used for background user performance bar (home page)
  --grey-700: #202227; // used for bottom of the session selector container (home page) border bottom choose metric header buttons
  --grey-300: #CBCBCB; //font in the combo charts model
  --grey-400: rgba(175, 175, 175, 1); //used in input boxes text
  --grey-50: rgba(243, 243, 243, 1);
  --grey-900: var(--panel-dark);
  --warning-orange: rgba(255, 153, 10, 1); // used int the warning icon color and the warning text in the warning editing scheduled session model
  --light-grey: #F2F2F2; //font used for titles in the session selector desktop
  --grey-200: #E2E2E2; //border on previous sessions
  --grey-1000: #595a5b; // used for color in search bar groups
  --shadow-slate: rgba(49, 53, 60, 1);

  --modal-border: #222A3F;
  --calendar-header: #647fbe;
  --input-background: #1D2333;
  --segment-background: #1D2334;
  --average-line: #ffffff;
  --backdrop: #535659;
  --button-bg-primary: var(--primary-blue);
  --azure-radiance-500: rgba(40, 156, 255, 1); // used as the calendat bulk gameday colour
  --sync-reminder-panel: #172030;
  --icon-color: #656F96;
  --background-rgb: rgb(107, 101, 101);
  --midnight-sable: #17181C; // color of the modal 4
  --button-hover:  #36383c;

  
  // Chart colours
  --metric-total-1: rgba(23, 129, 255, 0.30);
  --metric-1st-half-1: rgba(255, 255, 255, 0.30);
  --metric-2nd-half-1: rgba(15, 54, 146, 0.3);


  --metric-total-2: rgba(144, 153, 203, 0.30);
  --metric-2nd-half-2: rgba(245, 58, 160, 0.30);
  --metric-1st-half-2: rgba(36, 219, 201, 0.30);

  --metric-total-1-border: #1781FF;
  --metric-1st-half-1-border: rgba(255, 255, 255);
  --metric-2nd-half-1-border: rgba(23, 129, 255, 0.30);


  --metric-total-2-border: white;
  --metric-1st-half-2-border: #FFFFFF;
  --metric-2nd-half-2-border: rgba(245, 58, 160);


  //Combo Chart Colours
  // does not matter for bar chart
  --metric-total-1-combo: rgba(23, 129, 255, 0.30);

  --metric-1st-half-1-combo: rgba(21, 102, 198, 0.3);
  --metric-2nd-half-1-combo: rgba(23, 129, 255, 0.3);


  // does not matter for bar chart
  --metric-total-2-combo: rgba(255, 255, 255, 0.30);

  --metric-1st-half-2-combo: rgba(15, 54, 146, 0.30);
  --metric-2nd-half-2-combo: rgba(166, 206, 227, 0.30);

  --metric-total-1-combo-border: #1781FF;
  --metric-total-1-combo-border-20:  rgba(23, 129, 255, 0.2);

  --metric-1st-half-1-combo-border: rgba(21, 102, 198, 1);
  --metric-2nd-half-1-combo-border: rgba(23, 129, 255, 1);


  --metric-total-2-combo-border: rgba(255, 255, 255, 1);
  --metric-1st-half-2-combo-border: #FFFFFF;
  --metric-2nd-half-2-combo-border: rgba(245, 58, 160);

  --combo-chart-colour-set-1-1: rgba(23, 129, 255, 1);
  --combo-chart-colour-set-1-2: rgba(23, 129, 255, 1);
  --combo-chart-colour-set-1-0: rgba(23, 129, 255, 1);

  --combo-chart-colour-set-1-1-03: rgba(23, 129, 255, 0.8);
  --combo-chart-colour-set-1-2-03: rgba(23, 129, 255, 0.6);
  --combo-chart-colour-set-1-0-03: rgba(23, 129, 255, 0.3);

  --combo-chart-colour-set-2-1: rgb(136, 213, 255);
  --combo-chart-colour-set-2-2: rgb(181, 215, 255);
  --combo-chart-colour-set-2-0: rgb(255, 255, 255);

  --combo-chart-colour-set-2-1-03: rgba(136, 213, 255, 0.3);
  --combo-chart-colour-set-2-2-03: rgb(181, 215, 255, 0.3);
  --combo-chart-colour-set-2-0-03: rgb(255, 255, 255);


  // pdf combo chart colours
  --pdf--combo-chart-colour-set-1-1: rgba(23, 129, 255, 1);
  --pdf--combo-chart-colour-set-1-2: rgba(23, 129, 255, 1);
  --pdf--combo-chart-colour-set-1-0: rgba(23, 129, 255, 1);

  --pdf--combo-chart-colour-set-1-1-03: rgba(23, 129, 255, 0.8);
  --pdf--combo-chart-colour-set-1-2-03: rgba(23, 129, 255, 0.6);
  --pdf--combo-chart-colour-set-1-0-03: rgba(23, 129, 255, 0.3);

  --pdf--combo-chart-colour-set-2-1: rgb(136, 213, 255);
  --pdf--combo-chart-colour-set-2-2: rgb(181, 215, 255);
  --pdf--combo-chart-colour-set-2-0: #DCDCDC;

  --pdf--combo-chart-colour-set-2-1-03: rgba(136, 213, 255, 0.3);
  --pdf--combo-chart-colour-set-2-2-03: rgb(181, 215, 255, 0.3);
  --pdf--combo-chart-colour-set-2-0-03: #DCDCDC;


  // for tool tips easy way of accessing colours dynamically for gameday colours
  --pdf--combo-chart-colour-set-1-0-2: #1781FF;
  --pdf--combo-chart-colour-set-1-0-2-03: rgba(23, 129, 255, 0.30);
  --pdf--combo-chart-colour-set-2-0-2: rgba(128, 128, 128, 1);
  --pdf--combo-chart-colour-set-2-0-2-03: rgba(128, 128, 128, 0.3);


  --combo-chart-colour-set-1-0-2: #1781FF;
  --combo-chart-colour-set-1-0-2-03: rgba(23, 129, 255, 0.30);
  --combo-chart-colour-set-2-0-2: rgba(255, 255, 255, 1);
  --combo-chart-colour-set-2-0-2-03: rgba(255, 255, 255, 0.3);


  --single-chart-colour-0: #1781FF;
  --single-chart-colour-1: rgba(255, 255, 255);
  --single-chart-colour-2: rgba(23, 129, 255, 0.30);

  --area-chart-1: rgba(166, 206, 227, 1);
  --area-chart-2: rgba(0, 97, 212, 1);


  --pdf-metric-total-1: #405da7;
  --pdf-metric-1st-half-1: #c8c8c8;
  --pdf-metric-2nd-half-1: #469bff;

  --pdf-metric-total-2: rgba(100, 110, 160, 0.50);
  --pdf-metric-2nd-half-2: rgba(200, 0, 100, 0.50);
  --pdf-metric-1st-half-2: rgba(10, 160, 140, 0.50);

  --pdf-metric-total-1-border: #b4c0dd;
  --pdf-metric-1st-half-1-border: #bfbfbf;
  --pdf-metric-2nd-half-1-border: #b6d7ff;

  --pdf-metric-total-2-border: rgba(10, 160, 140);
  --pdf-metric-1st-half-2-border: #888787;
  --pdf-metric-2nd-half-2-border: rgba(200, 0, 100);

  --ion-color-step-100: transparent;

  // zones colours
  // background
  --zone-color-1: rgba(175, 175, 175, 0.3);
  --zone-color-2: rgba(15, 54, 146, 0.3);
  --zone-color-3: rgba(0, 102, 42, 0.3);
  --zone-color-4: rgba(251, 142, 2, 0.3);
  --zone-color-5: rgba(218, 92, 92, 0.3);

  //border
  --zone-color-1-border: rgba(255, 255, 255, 1);
  --zone-color-2-border: rgba(23, 129, 255, 1);
  --zone-color-3-border: rgba(6, 193, 103, 1);
  --zone-color-4-border:rgba(255, 185, 78, 1);
  --zone-color-5-border: rgba(218, 92, 92, 1);

  // zonal breakdown card values

  // color
  --zone-breakdown-5: rgba(88, 51, 55, 1);
  --zone-breakdown-4: rgba(98, 66, 28, 1);
  --zone-breakdown-3: rgba(22, 54, 40, 1);
  --zone-breakdown-2: rgba(27, 40, 71, 1);
  --zone-breakdown-1: rgba(75, 76, 80, 1);

  // border
  --zone-breakdown-5-border: rgba(218, 92, 92, 1);
  --zone-breakdown-4-border: rgba(251, 142, 2, 1);
  --zone-breakdown-3-border: rgba(0, 102, 42, 1);
  --zone-breakdown-2-border: rgba(15, 54, 146, 1);
  --zone-breakdown-1-border: rgba(255, 255, 255, 1);

  --font-xxxsm: 0.31rem;
  --font-xxsm: 0.55rem;
  --font-xsm: 0.6rem;
  --font-sm: 0.75rem;
  --font-md: 0.875rem;
  --font-md-lg: 0.855rem;
  --font-lg: 1.00rem;
  --font-xl: 1.1rem;
  --font-xxl: 1.725rem;
  --font-xxxl: 1.8rem;
  --font-xxxxl: 2rem;
  --font-xxxxxl: 2.75rem;
  --line-height-sm: 16px;

  --line-height-xxxsm: calc(var(--font-xxxsm) * 1.4);
  --line-height-xxsm: calc(var(--font-xxsm) * 1.4);
  --line-height-xsm: calc(var(--font-xsm) * 1.4);
  --line-height-sm: calc(var(--font-sm) * 1.4);
  --line-height-md: calc(var(--font-md) * 1.4);
  --line-height-md-lg: calc(var(--font-md-lg) * 1.4);
  --line-height-lg: calc(var(--font-lg) * 1.4);
  --line-height-xl: calc(var(--font-xl) * 1.4);
  --line-height-xxl: calc(var(--font-xxl) * 1.4);
  --line-height-xxxxl: calc(var(--font-xxxxl) * 1.4);

  @media (min-width: 1200px) {
    --font-xxxsm: calc(0.31rem * 1.01);;
    --font-xxsm: calc(0.55rem * 1.01);
    --font-xsm: calc(0.75rem * 1.01); /* Increase font size by 25% */
    --font-sm: calc(0.85rem * 1.01);
    --font-md: calc(1rem);
    --font-md-lg: 1.25rem;
    --font-lg: calc(1.25rem * 1.2);
    --font-xl: calc(1.5rem * 1.00);
    --font-xxl: calc(1.725rem * 1.01);
    --font-xxxl: calc(2rem * 1);
    --font-xxxxl: calc(2.6rem * 1.01);
    --font-xxxxxl: calc(3rem * 1);


    --line-height-xxxsm: calc(var(--font-xxxsm) * 1.4);
    --line-height-xxsm: calc(var(--font-xxsm) * 1.4);
    --line-height-xsm: calc(var(--font-xsm) * 1.4);
    --line-height-sm: calc(var(--font-sm) * 1.4);
    --line-height-md: calc(var(--font-md) * 1.4);
    --line-height-md-lg: calc(var(--font-md-lg) * 1.4);
    --line-height-lg: calc(var(--font-lg) * 1.4);
    --line-height-xl: calc(var(--font-xl) * 1.4);
    --line-height-xxl: calc(var(--font-xxl) * 1.4);
    --line-height-xxxxl: calc(var(--font-xxxxl) * 1.4);
  }


}

.primary-gradient {
  background: var(--panel-dark);
}

.panel-gradient {
  background: rgb(0, 0, 254);
  background: -moz-linear-gradient(180deg, var(--panel-gradient-start) 0%, var(--panel-gradient-end) 90%);
  background: -webkit-linear-gradient(180deg, var(--panel-gradient-start) 0%, var(--panel-gradient-end) 90%);
  background: linear-gradient(180deg, var(--panel-gradient-start) 0%, var(--panel-gradient-end) 90%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=var(--panel-gradient-start), endColorstr=var(--panel-gradient-end), GradientType=1);
}

.dark-panel-gradient {
  background: var(--panel-dark);
  border-radius: 4px;
}

.user-tutorial-model {
  background-color: var(--grey-800);
  border-radius: 4px;
}

.dark-panel-gradient-modal {
  background-color: var(--grey-800);
  height: 100%;
  border-radius: 8px;
}

.dark-panel-gradient-modal-height-auto {
  background-color: var(--grey-800);
  border-radius: 8px;
}

.dark-panel-gradient-modal-black {
  background-color: black;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  color: white;
}





