//Play font
@font-face {
  font-family: Play;
  src: url('../assets/fonts/Play-Regular.ttf');
  font-weight: 500;
}

// Barlow font
@font-face {
  font-family: 'Barlow';
  src: url('/assets/fonts/Barlow-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('/assets/fonts/Barlow-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Barlow';
  src: url('/assets/fonts/Barlow-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Barlow';
  src: url('/assets/fonts/Barlow-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Barlow';
  src: url("/assets/fonts/Barlow-LightItalic.ttf") format('truetype');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'Barlow';
  src: url("/assets/fonts/Barlow-SemiBoldItalic.ttf") format('truetype');
  font-style: italic;
  font-weight: 600;
}


//Open Sans
@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/OpenSans-Regular.ttf');
  font-weight: 500;
}

//Oswald
@font-face {
  font-family: 'Oswald';
  src: url('../assets/fonts/Oswald-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Oswald';
  src: url('../assets/fonts/Oswald-Bold.ttf');
  font-weight: bold;
}


body, span, button, h1, h2, h3, h4, h5, h6, p, ion-item, ion-title {
  font-family: Barlow, sans-serif !important;

  user-select: none;
  font-weight: 600;
  scrollbar-color: var(--fourth-colour); /* Change these colors as needed */
  letter-spacing: 0 !important;
  color: white;
}

.flex-1 {
  flex: 1;
}

h1 {
  font-size: 22px;
}

h2 {
  font-size: 20px;
  font-weight: bold;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 13px;
}

span {
  font-size: 12px;
}


ion-button {
  letter-spacing: 0 !important;
}


ion-button {
  font-family: Barlow, sans-serif !important;
  --border-radius: 5px;
  --padding-top: 7px;
  --padding-bottom: 7px;
  --padding-start: 15px;
  --padding-end: 15px;
  outline: none;
  -webkit-appearance: none;
  font-weight: 600 !important;
  font-size: var(--font-sm) !important;
  --background-hover: var(--white);
  --background-activated: var(--white);
  --background-focused: var(--white);
  text-transform: none;
}

.zonal-legend-gaps {
  gap: 6px;
}

.zone-color-1 {
  background-color: var(--zone-color-1-border) !important;
}

.zone-color-2 {
  background-color: var(--zone-color-2-border) !important;
}

.zone-color-3 {
  background-color: var(--zone-color-3-border) !important;
}

.zone-color-4 {
  background-color: var(--zone-color-4-border) !important;
}

.zone-color-5 {
  background-color: var(--zone-color-5-border) !important;
}

.white-button {
  --background: var(--white) !important;
  --background-hover: var(--button-hover)!important;
  color: var(--dark-bg) !important;
  &:hover{
    --background: var(--grey-50)!important;
  }
}

.h-100 {
  height: 100% !important;
}

.h-50 {
  height: 50% !important;
}

.w-100 {
  width: 100% !important;
}

.w-25 {
  width: 25% !important;
}

.w-5 {
  width: 5% !important;
}

.p0 {
  padding: 0 !important;
}

.m0 {
  margin: 0 !important;
}

.header-md::after {
  display: none;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-top-bottom {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.no-padding-left-right {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.vertical-align-content {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.horizontal-end {
  display: flex !important;
  justify-content: center !important;
  align-items: flex-end !important;
}

.vertical-end {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: flex-end !important;
}

.vertical-horizontal-end {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: flex-end !important;
}

.player-list-container {
  width: 50vw;
}

.players-container {
  width: 100%;
  height: 290px;
  border-radius: 5px;
  border: 1px solid var(--fourth-colour-opacity-01);
  background: var(--fourth-colour-opacity-003);
}


.select-metric-list-container {
  width: 53vw;
}

.horizontal-start {
  display: flex !important;
  justify-content: center !important;
  align-items: flex-start !important;
}

.vertical-align-start-justify-end {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: flex-start !important;
}

.vertical-align-start-content {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}

.vertical-align-between-content {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.vertical-align-between-content-stretch {
  display: flex !important;
  justify-content: space-between !important;
  align-items: stretch !important;
}

.vertical-align-between-end-content {
  display: flex !important;
  justify-content: space-between !important;
  align-items: flex-end !important;
}

.settings-nav-button::part(native) {
  border-color: white;
  color: white;
  border-radius: 6px;
  width: 100%;
}

.item-list-container {
  background-color: var(--fourth-colour-opacity-003);
  border: var(--fourth-colour-opacity-01);
}

.chart-title-text {
  width: 260px !important;
}

.chart-title-text-gameday {
  width: 160px !important;
  text-align: center;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

.combo-chart-labels-container {
  width: 35%;
  display: flex;
}

.vertical-align-end-content {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
}

.combo-align-start-content {
  display: flex !important;
  align-items: center !important;
  justify-content: space-evenly;
  gap: 9px;
}

.combo-content {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
}


.hor-align-end {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
}

.ellipsis-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.avatar-background-dark {
  width: 128px;
  height: 128px;
  background-color: var(--grey-700);
  border-radius: 50%;
}

ion-tab-button {
  background-color: transparent;
  max-width: 125px;
}

.dropdown-text {
  font-size: var(--font-sm);
  color: var(--fourth-colour);
  font-weight: 400;
}

.panel-subheading-text {
  font-size: var(--font-md);
  color: var(--fourth-colour);
}

.error-message-modal {
  font-size: var(--font-xsm);
  color: #DA5C5C;
  font-weight: bold;
}

.nav-buttons {

}


.chevron-left-auth {
  width: 35px;
  height: 35px;
  position: absolute;
  background-color: var(--white);
  top: 7%;
  left: 4%;
}

.code-badge {
  background-color: transparent;
  padding: 6px 12px;
  border-radius: 30px;
  margin-left: 16px;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid var(--fourth-colour-opacity-20);
}


.nav-buttons-manage-templates {
  font-size: var(--font-sm) !important;
  font-weight: bold !important;
  color: var(--fourth-colour) !important;
}

.primary-button {
  --background: var(--primary-blue);
  --color: var(--white);
  -webkit-appearance: none !important;
}


.panel-button {
  margin: 0 !important;
  --padding-top: 10px;
  --background: var(--grey-700);
  height: fit-content;
  --color: var(--white);
  --border-color: var(--grey-700);
  --background-activated: var(--grey-700);
  --background-focused: var(--grey-700);
  --padding-start: 12px;
  --padding-end: 12px;
  --padding-bottom: 10px;
  border-radius: 4px;
}

.update-call-button {
  --background: var(--panel-dark-gradient-start);
  --color: var(--panel-text);
  --border-color: var(--panel-dark-gradient-start);
  --color-focused: var(--panel-dark-gradient-start);
  --color-activated: var(--panel-dark-gradient-start);
  --background-activated: var(--dark-hover);
  --background-focused: var(--dark-hover);
  --padding-start: 16px;
  --padding-end: 16px;
}

.panel-button-light {
  --background: var(--panel-button-light);
  --color: var(--dark-bg);
  --border-color: var(--panel-button-light);
  --color-focused: var(--panel-button-light);
  --color-activated: var(--panel-dark-gradient-start);
}

.panel-button-transparent {
  --background: transparent;
  --color: var(--white);
  --border-color: transparent;
  --color-focused: var(--panel-text);
  --color-activated: var(--panel-text);
  --background-activated: var(--dark-hover);
  --background-focused: var(--dark-hover);
  --padding-start: 16px;
  --padding-end: 16px;
}

.player-comparison-change-button {
  --background: black;
  --color: var(--white);
  font-size: var(--font-sm);
  --border-radius: 6px !important;
  --border-color: var(--white);
  --border-style: solid;
  --border-width: 1px;
  z-index: 60;
}

.lh-30px {
  line-height: 24px;
}

.secondary-panel-button {

  margin: 0 !important;
  --padding-top: 15px;
  --background: var(--grey-700);
  height: fit-content;
  --color: var(--white);
  --border-color: var(--grey-700);
  --background-activated: var(--grey-700);
  --background-focused: var(--grey-700);
  --padding-start: 22px;
  --padding-end: 22px;
  --padding-bottom: 15px;
  --box-shadow: none;
  --background-hover: var(--button-hover)!important;
  &:hover{
    --background: var(--button-hover)!important;
  }
}


ion-button.nav-button-active {
  //--background: var(--white) !important;
  --color: var(--white);
}

.cal-month-view-icon {
  -webkit-mask: url('../assets/images/svg/calendar-view-month.svg') no-repeat center;
  mask: url('../assets/images/svg/calendar-view-month.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.pitch-switch-top-filled-in-icon {
  -webkit-mask: url('../assets/images/svg/pitch-switch-top-filled-in.svg') no-repeat center;
  mask: url('../assets/images/svg/pitch-switch-top-filled-in.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.pitch-switch-bottom-filled-in-icon {
  -webkit-mask: url('../assets/images/svg/pitch-switch-bottom-filled-in.svg') no-repeat center;
  mask: url('../assets/images/svg/pitch-switch-bottom-filled-in.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.pitch-switch-right-filled-in-icon {
  -webkit-mask: url('../assets/images/svg/pitch-switch-right-filled-in.svg') no-repeat center;
  mask: url('../assets/images/svg/pitch-switch-right-filled-in.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.pitch-switch-left-filled-in-icon {
  -webkit-mask: url('../assets/images/svg/pitch-switch-left-filled-in.svg') no-repeat center;
  mask: url('../assets/images/svg/pitch-switch-left-filled-in.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}


.cal-list-menu-icon {
  -webkit-mask: url('../assets/images/svg/calendar-list-icon.svg') no-repeat center;
  mask: url('../assets/images/svg/calendar-list-icon.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.chevron-down-icon {
  -webkit-mask: url('../assets/images/svg/chevron-down.svg') no-repeat center;
  mask: url('../assets/images/svg/chevron-down.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}


.chevron-up-icon {
  -webkit-mask: url('../assets/images/svg/chevron-up.svg') no-repeat center;
  mask: url('../assets/images/svg/chevron-up.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}


.ellipsis-icon {
  -webkit-mask: url('../assets/images/svg/ellipsis.svg') no-repeat center;
  mask: url('../assets/images/svg/ellipsis.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.warning-icon {
  -webkit-mask: url('../assets/images/svg/warning.svg') no-repeat center;
  mask: url('../assets/images/svg/warning.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.warning-icon-editing-scheduled-session {
  width: 25px;
  height: 25px;
  background: var(--warning-orange);
}

.large-ellipsis-icon {
  -webkit-mask: url('../assets/images/svg/large-elipsis-dots.svg') no-repeat center;
  mask: url('../assets/images/svg/large-elipsis-dots.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.up-down-arrows-icon {
  -webkit-mask: url('../assets/images/svg/up-down-arrows.svg') no-repeat center;
  mask: url('../assets/images/svg/up-down-arrows.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.line-legend-icon {
  -webkit-mask: url('../assets/images/svg/line-legend-icon.svg') no-repeat center;
  mask: url('../assets/images/svg/line-legend-icon.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.donut-legend-icon {
  -webkit-mask: url('../assets/images/svg/donut-legend-icon.svg') no-repeat center;
  mask: url('../assets/images/svg/donut-legend-icon.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.question-mark-icon {
  -webkit-mask: url('../assets/images/svg/question-mark.svg') no-repeat center;
  mask: url('../assets/images/svg/question-mark.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  background-color: var(--fourth-colour);
}

.large-ellipsis {
  background-color: white;
  width: 17px;
  height: 17px;
}

.square-icon {
  -webkit-mask: url('../assets/images/svg/square.svg') no-repeat center;
  mask: url('../assets/images/svg/square.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.triangle-icon {
  -webkit-mask: url('../assets/images/svg/triangle.svg') no-repeat center;
  mask: url('../assets/images/svg/triangle.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.triangle-down-icon {
  -webkit-mask: url('../assets/images/svg/triangle-down.svg') no-repeat center;
  mask: url('../assets/images/svg/triangle-down.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.diamond-icon {
  -webkit-mask: url('../assets/images/svg/diamond.svg') no-repeat center;
  mask: url('../assets/images/svg/diamond.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.legend-custom-combo-icon {
  width: 14px;
  height: 14px;
}

.ellipse-icon-size {
  width: 15px;
  height: 15px;
  background-color: var(--fourth-colour);
}


.calendar-view-mode-icon-size {
  width: 24px;
  height: 17px;
  background-color: var(--fourth-colour);
}

.calendar-view-mode-selected {
  background-color: var(--white);

}


.resent-message-text {
  font-size: var(--font-md);
  color: #06C167;
  font-weight: 600;
}


.scroll-vertical {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.item-interactive {
  --background-hover: transparent;
  --border-width: 0;
  --ripple-color: transparent;
}

.dark-panel {
  background-color: var(--panel-dark);
  --background: var(--panel-dark);
}

.session-selector-container {
  background-color: var(--grey-800);
  border-radius: 4px;
  // padding-top: 10px;
  // width: 50vw !important;
}

.session-selector-list-container {
  // width: fit-content !important;
  // padding-top: 0 !important;
  background-color: var(--grey-800) !important;
}

.panel-bg {
  background: linear-gradient(180deg, #1A202E 0%, #121524 100%);
  box-shadow: 0 51px 65px rgba(127, 156, 170, 0.18);
}

.exclamation-mark-icon {
  -webkit-mask: url('../assets/images/svg/exclamation-mark-icon.svg') no-repeat center;
  mask: url('../assets/images/svg/exclamation-mark-icon.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.sessions-popover::part(content), .sessions-popover::part(arrow)::after {
  background: var(--grey-800);
  border: 1px solid var(--background-rgb);

}


.light-panel {
  border-radius: 5px;
  background-color: var(--white);
}


[hidden] {
  display: none !important;
}

.white-label {
  font-size: 12px;
  font-weight: bold;
  color: var(--white)
}

.session-selector-modal {
  --width: fit-content;
  --height: auto;
  --background: transparent;
  --backdrop-opacity: 0.8 !important;
  --max-height: 95vh;
}



.standard-modal {
  --width: fit-content;
  --height: auto;
  --background: transparent;
  --backdrop-opacity: 0.8 !important;
  --max-height: 95vh;
}

.white-space-nowrap{
  white-space: nowrap;
}

.modal-transparent {
  --background: transparent!important;
  --ion-backdrop-color: transparent!important;
}

.loading-modal-class {
  --background: rgba(0, 0, 0, 0.5);
  --width: 100%;
  --height: 100%;
}

.standard-model-grey-box-shadow {
   --box-shadow: 0px 0px 3px 3px rgb(13 13 13)
}
.result-explainer-modal {
  --width: 50vw;
  --height: fit-content;
  --background: transparent;
  --backdrop-opacity: 0.75 !important;
}

.consultation-booking-confirm-modal {
  --width: 560px;
  --height: 450px;
  --background: transparent;
  --backdrop-opacity: 0.8 !important;
}

.enter-result-modal {
  --width: 90vh;
  --height: fit-content;
  --background: transparent;
  --backdrop-opacity: 0.8 !important;
}

.comparison-selector-modal {
  --width: 70%;
  --min-width: 500px;
  --height: 60vh;
  //--max-width: 700px;
  //--min-height: 400px;
  //--max-heigth: 500px;
  --background: transparent;
  --backdrop-opacity: 0.8 !important;
}

.player-report-modal {
  --width: 100%;
  //--min-width: 500px;
  //--max-width: 900px;
  --height: 100%;
  //--min-height: 400px;
  //--max-height: 500px;
  --background: transparent;
  --backdrop-opacity: 0.8 !important;
}

.player-report-selector-modal {
  --background: transparent;
  //--min-width: 50vw;
  --width: fit-content;
  --height: fit-content;
  --max-height: 95vh;
  --backdrop-opacity: 0.8 !important;
}

.overlay-modal {
  --width: 100%;
  --height: 100%;
  --background: transparent;
  --backdrop-opacity: 0.8 !important;
}

.email-share-modal {
  --background: transparent;
  --width: fit-content;
  --height: fit-content;
  --max-height: 95vh;
}

.change-password-modal {
  --background: transparent;
  --width: 450px;
  --height: fit-content;
  --backdrop-opacity: 0.8 !important;
}

.add-squad-modal {
  --background: transparent;
  --width: 870px;
  --height: fit-content;
  --backdrop-opacity: 0.8 !important;
}


.user-tutorial-modal {
  --background: transparent;
  --width: 870px;
  --height: fit-content;
  --backdrop-opacity: 0.8 !important;
  --box-shadow: none !important;
}

.edit-player-modal {
  --background: transparent;
  --width: 550px;
  --height: fit-content;
  --backdrop-opacity: 0.8 !important;
}

.add-session-modal {
  --background: transparent;
  --width: 550px;
  --height: fit-content;
  --backdrop-opacity: 0.8 !important;
  --box-shadow: none !important;
}

.picker-button.sc-ion-picker-md, .picker-button.ion-activated.sc-ion-picker-md {
  color: var(--white);
  text-transform: none;
  font-weight: 600;
}

.ion-activated{
  color: var(--white);
  --background-hover: transparent!important;
  --background: transparent!important;
}

.picker-opt {
  color: var(--fourth-colour) !important;

}

.picker-opt-selected {
  color: var(--white) !important;
}

.book-consultancy-modal {
  --background: transparent;
  --width: 825px;
  --height: 515px;
  --max-height: 95vh;
  --backdrop-opacity: 0.8 !important;
}

.feedback-modal {
  --height: 400px;
  --width: 600px;
  --background: transparent;
  --max-height: 95vh;
  --backdrop-opacity: 0.8 !important;
}

.book-consultancy-modal-confirmation {
  --background: transparent;
  --max-height: 95vh;
  --backdrop-opacity: 0.8 !important;
}



.merge-sessions-modal {
  --background: transparent;
  --width: fit-content;
  overflow: scroll;
  --backdrop-opacity: 0.8 !important;
  --height: fit-content;
  //--max-height: 80vh;
}

.choose-metrics-modal {
  --background: transparent;
  --width: 900px;
  --height: 330px;
  --max-height: 95vh;
  --backdrop-opacity: 0.8 !important;
  --border-radius: 5px;
}

.sync-reminder-modal, .modal-structure-1, .add-bulk-gameday-model {
  --background: transparent;
  --width: fit-content;
  --height: fit-content;
  --max-height: 95vh;
  --backdrop-opacity: 0.8 !important;
  --overflow: allow;
}

.modal-structure-1, .modal-structure-5 {
  // --max-width: 1200px;
  --height: fit-content;
  --width: fit-content;
}

.modal-structure-5 {
  --backdrop-opacity: 0.4 !important;
}


.date-selector-modal {
  --background: transparent;
  --width: 500px;
  --height: 450px;
  --max-height: 95vh;
  --backdrop-opacity: 0.8 !important;
}

.invite-code-modal {
  --background: transparent;
  --width: 550px;
  --height: fit-content;
  --backdrop-opacity: 0.8 !important;
}

.combo-charts-modal {
  --background: transparent;
  --width: 680px;
  --height: 500px;
  --max-height: 95vh;
  --backdrop-opacity: 0.8 !important;
}

.past-consultancy-modal {
  --background: transparent;
  --width: 500px;
  --height: 285px;
  --backdrop-opacity: 0.8 !important;
}

.update-consultancy-modal {
  --background: transparent;
  --width: 640px;
  --height: 380px;
  --backdrop-opacity: 0.8 !important;
}

.update-notes-modal {
  --background: transparent;
  --width: 640px;
  --height: 500px;
  --backdrop-opacity: 0.8 !important;
}

.no-padding-btn {
  --padding-start: 0 !important;
  --padding-end: 0 !important;
}

.choose-metric-type-button {
  font-size: var(--font-md);
  color: var(--font-color-1);
  font-weight: 600;
  text-transform: none;
  min-height: 10px;
  --padding-top: 9px;
  --padding-bottom: 10px;
  --indicator-color: var(--fourth-colour);
  --indicator-height: 1px;
  --color-checked: var(--white);
  --color-hover: var(--white);
}

.report-button {
  --padding-bottom: 15px;
  --padding-top: 15px;
}

.comparison-selection-button {
  --padding-top: 20px;
  --padding-bottom: 20px;
  --background-hover: transparent;
  --background-focused: transparent;
  --color-checked: var(--white);
  --color-hover: var(--white);
}

ion-spinner{
  transform: scale(2);
}


.comparison-selection-button-heading {
  //max-width: 120px !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  display: inline !important;
  font-size: 14px;
}

.edit-speed-zone-content-symbols-item-speed-input-entry-box{
  flex: 0!important;
  text-align: center;
  & input{
    width: 32px!important;
    padding: 0!important;
  }
}

.heart-rate-input input {
  height: 100%!important;
  width: 32px!important;
  overflow: scroll!important;
}

.heart-rate-input-last{
  flex: 1!important;
  & input{
    width: 100%!important;
  }
}



.selected-metric-group {
  color: var(--white);
}

.non-selected-metric-group {
  color: var(--fourth-colour);
}

.session-selector-ion-segment {
  width: 75% !important;
}

.choose-metric-ion-segment {
  //width: 31% !important;

}

.choose-comparison-ion-segment {
  width: 71% !important;
}

ion-segment-button {
  font-weight: 600;
  text-transform: none;
}

.app-modal-header {
  border-bottom: 1px solid var(--grey-700);
  font-size: var(font-lg);
  //padding: 4px 16px;
}

ion-radio.choose-metric-checkbox {
  &:not([disabled]) {
      &:hover::part(container) {
          border-color: var(--white);
          opacity: 1;
          padding: 20%;
          background-color: var(--white);
      }
  }
}

.choose-metric-checkbox, .choose-group-checkbox {
  --background: transparent;
  --color-checked: var(--white);
  &:hover {
    --color-checked: var(--white);
  }
}

.choose-metric-checkbox {
  --checkmark-width: 12px;
  --size: 40px;
  margin-right: 9px;
}

.choose-group-checkbox {
  --checkmark-width: 12px;
  --size: 90px;
  height: 100%;
}

.new-tag {
  padding: 1.5px 6px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.50);
  backdrop-filter: blur(2.5px);
  text-transform: uppercase;
}


.choose-metric-checkbox::part(mark) {
  padding: 6px;
}

.choose-group-checkbox::part(mark) {
  padding: 8px;
}

.player-reports-checkbox {
  --background: transparent;
  --color-checked: var(--white);
  --checkmark-width: 22px;
  --size: 60px;
  margin-right: 9px;
  display: flex;

  width: 24px;
  height: 24px;
}


.player-reports-checkbox::part(container) {
  border-color: var(--fourth-colour);
  border-width: 1px;
}

.player-reports-checkbox::part(mark) {
  padding: 8px;
}

.choose-metric-checkbox::part(container) {
  padding: 7px;
  border-color: var(--fourth-colour);
  border-width: 1px;
  width: 17.5px;
  height: 17.5px;
}

.choose-group-checkbox::part(container) {
  padding: 11px;
  border-color: var(--fourth-colour);
  border-width: 1px;
  width: 23.5px;
  height: 11.5px;
}

.sc-ion-modal-md-h {
  --box-shadow: none !important;
}

.match-session p {
  color: red !important;
  font-weight: bold !important;
}

.loading-spinner svg {
  width: 80px !important;
  height: 80px !important;
}

.transparent-item {
  --background: transparent;
  --color: var(--white);
  --border-color: var(--light, #fff);
}

.light-input {
  --background: transparent;
  --color: var(--white);
}

.non-uppercase {
  text-transform: none !important;
}

.ios .auth-input-item {
  font-size: 12px;
  font-weight: bold;
  --min-height: 50px;
  --padding-start: 0;
  --inner-padding-bottom: 10px;
}

.md .auth-input-item {
  font-size: 12px;
  font-weight: bold;
  --min-height: 50px;
  --padding-start: 0;
}

.auth-btn {
  min-width: 200px;
  height: 35px !important;
  font-size: 12px !important;
  -webkit-appearance: none;
}

.auth-small-text {
  text-decoration: underline;
  font-weight: 400;
  color: var(--fourth-colour);
  font-size: var(--font-sm);
}

.auth-submit-btn {
  width: 80%;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

.link-text {
  color: var(--primary-blue);
  text-align: center;
  font-size: 13px;
}

.font-bold {
  font-weight: bold;
}

.fade-in {
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.4s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.transparent-content {
  --background: transparent !important;
}

.span-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  width: 100%;
  overflow-x: hidden;
}

.modal-input {
  border-radius: 4px;
  border: 1px solid rgba(175, 175, 175, 0.2);
  background: var(--fourth-colour-opacity-003);
  --padding-start: 8px !important;
  --padding-end: 8px;
  height: 45px;
  font-weight: 400;
  outline: none;
  padding-left: 8px !important;
  padding-right: 8px !important;
  color: var(--fourth-colour);
  font-size: var(--font-md);
}

.modal-input-gaa {
  --background: var(--input-background);
  background: var(--input-background);
  font-size: 11px;
  height: 40px;
  outline: none;
  border-radius: 4px;
  --padding-start: 8px !important;
  --padding-end: 8px;
  padding-left: 8px !important;
  padding-right: 8px !important;
  color: var(--white);
  border: 1px solid var(--fourth-colour-opacity-01);
  background: var(--fourth-colour-opacity-003);
}

.modal-input-gaa input {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.dropdown-input {
  cursor: pointer !important;
}

.merge-date::part(native) {
  background: transparent !important;
  width: 100%;
  color: white;
  font-weight: bold;
  text-align: left;
  padding-left: 4px;
}

.info-text {
  color: var(--panel-text);
  font-size: 11px;
  text-transform: initial !important;
  font-weight: normal;
}


//app scrollbars

::-webkit-scrollbar {
  width: 10px;
  height: 4px;
  background-color: transparent;
  -webkit-border-radius: 100px;
}

//::-webkit-scrollbar:hover {
//  background: var(--dark-bg);
//}

::-webkit-scrollbar-thumb:vertical {
  background: var(--fourth-colour);
  -webkit-border-radius: 100px;
  background-clip: padding-box;
  border: 2px solid transparent;
  min-height: 10px;
}

::-webkit-scrollbar-thumb:vertical:active {
  background: var(--fourth-colour);
  -webkit-border-radius: 100px;
}

::-webkit-scrollbar-thumb:horizontal {
  background: var(--fourth-colour);
  -webkit-border-radius: 100px;
  background-clip: padding-box;
  border: 2px solid transparent;
  min-height: 10px
}

::-webkit-scrollbar-thumb:horizontal:active {
  background: var(--fourth-colour);
  -webkit-border-radius: 100px;
}

*::-webkit-scrollbar-corner {
  background: transparent;
}

.alert-dialog {
  text-transform: initial !important;
  font-family: Barlow, sans-serif !important;
  font-weight: 600 !important;
}

.alert-dialog .alert-button-group {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.alert-button {
  color: #007AFF !important;
  text-transform: none !important;
  font-size: var(--font-md) !important;
}

.alert-danger-btn {
  color: var(--ion-color-danger) !important;
}

.alert-dialog-btn {
  text-transform: inherit;
  border: 1px solid white;
  border-bottom-width: 0;
}


.alert-title.sc-ion-alert-md, .alert-radio-label.sc-ion-alert-md, .alert-head .sc-ion-alert-md {
  font-size: var(--font-lg);
  text-align: left;
  text-transform: inherit;
  color: var(--white) !important;
  color: var(--white) !important;
  font-weight: 500;
}

[aria-checked=true].sc-ion-alert-md .alert-radio-inner.sc-ion-alert-md{
  transform: scale(1.1) !important;
  background-color: var(--white);
}

[aria-checked=true].sc-ion-alert-md .alert-radio-icon.sc-ion-alert-md {
  border-color: var(--white);
}

.alert-radio-icon.sc-ion-alert-md{
  border-color: var(--fourth-colour);
}

.alert-radio-inner.sc-ion-alert-md{
  background-color: var(--white);
}

.alert-radio-inner{
  margin: 1px;
}

.alert-radio-icon.sc-ion-alert-md{
  border-width: 1px!important;
}



ion-select::part(icon) {
  color: var(--fourth-colour) !important;
  opacity: 1 !important;


}

ion-select::part(icon){
  content: url('../assets/images/svg/chevron-down-400.svg') !important;
  width: 12px;
  height: 12px;
  margin-left: 10px;

  &:hover {
    content: url('../assets/images/svg/chevron-down.svg') !important;
  }
}

ion-select:hover::part(icon) {
  content: url('../assets/images/svg/chevron-down.svg') !important;
  width: 12px;
  height: 12px;
  margin-left: 10px;
}



.alert-title {
  font-weight: 600 !important;
  text-align: center;
}

.alert-message {
  font-weight: 400 !important;
  text-align: center;
  color: white !important;
}

.alert-title.sc-ion-alert-ios {
  font-size: 18px;
  text-transform: inherit;
  font-family: sans-serif !important;
  color: var(--white) !important;
}

.alert-message.sc-ion-alert-md {
  font-size: var(--font-md);
  font-weight: 400 !important;
}

.alert-message.sc-ion-alert-ios {
  font-size: 16px;
  text-transform: inherit;
  font-family: sans-serif !important;
  font-weight: normal !important;
}

.alert-button-inner.sc-ion-alert-md {
  text-transform: inherit;
  font-family: sans-serif !important;
  font-weight: normal !important;
}

.acs-logo {
  background-color: white;
  -webkit-mask: url('../assets/images/svg/STATSports_Logo@1x.svg') no-repeat center;
  mask: url('../assets/images/svg/STATSports_Logo@1x.svg') no-repeat center;
  width: 90px;
  height: 90px;
}

.bin-logo {
  background-color: #DA5C5C;
  -webkit-mask: url('../assets/images/svg/bin.svg') no-repeat center;
  mask: url('../assets/images/svg/bin.svg') no-repeat center;
  width: 90px;
  height: 90px;
}

.exclamation-mark-icon-size {
  width: 42px;
  height: 42px;
  background-color: var(--fourth-colour);
}

.text-align-center {
  text-align: center;
}


.no-content-description-text {
  padding: 0 26vw;
}


.tutorial-page-content {
  background-image: url('../assets/images/athlete-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.black-friday-2024-promo-image {
  background-image: url('../assets/images/black-friday-2024-promo-image.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}


.alert-button-inner.sc-ion-alert-ios {
  text-transform: inherit;
  font-family: sans-serif !important;
  font-weight: normal !important;
  font-size: 16px;
}

.native-textarea.sc-ion-textarea-md {
  height: 100%;
  font-size: 12px;
  text-transform: initial;
}

.native-textarea.sc-ion-textarea-ios {
  height: 100%;
  font-size: 12px;
  text-transform: initial;
}

.app-segment {
  --background: transparent;
}

.app-segment-button {
  font-size: 12px;
  //max-width: 100px;
  min-height: 35px;
  max-height: 35px;
  margin-right: 10px;
  border-radius: 5px;
  --color: var(--panel-text);
  --border-width: 0;
  --background: var(--segment-background);
  --background-checked: var(--white);
  --color-checked: black;
}

ion-list{
  padding-top: 0!important;
}

.app-segment-button ion-label {
  font-weight: bold;
}

.background-transparent{
  --background: transparent;
}

.pointer {
  cursor: pointer;
}

.no-pointer-events {
  pointer-events: none;
}

.settings-list-item {
  --background: transparent;
  --color: var(--white);
  --min-height: 80px;
  --border-color: var(--grey-700);
  --inner-padding-end: 0;
  --ion-safe-area-right: 0px !important;
  --ion-safe-area-left: 6px !important;
  margin-right: 24px !important;


  &::part(native) {
    padding-right: 0 !important;
    background: transparent !important;
  }
}

.settings-list-item:last-child {
  --border-color: transparent;
}

.settings-segment-button {
  font-size: 12px;
  //max-width: 100px;
  min-height: 35px;
  height: 35px;
  margin-right: 10px;
  border-radius: 5px;
  --color: var(--panel-text);
  --border-width: 0;
  --background: var(--segment-background);
  --background-checked: var(--white);
  --color-checked: black;
  --padding-top: 0;
  --padding-bottom: 0;
  --margin-top: 0;
}

.settings-segment-button:last-child {
  margin-right: 0;
}

.metric-group-segment-button {
  font-size: 11px;
  //max-width: 100px;
  min-height: 30px;
  height: 30px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 5px;
  --color: var(--panel-text);
  --border-width: 0;
  --background: var(--segment-background);
  --background-checked: var(--white);
  --color-checked: black;
  --padding-top: 0;
  --padding-bottom: 0;
  --margin-top: 0;
}

.segment-label {
  font-weight: bold;
}

.auth-message {
  font-family: "Open Sans", serif !important;
  font-weight: bold;
  text-transform: initial;
  font-size: 12px;
}

.days button {
  padding: 3px 5px !important;
}

.close-btn {
  cursor: pointer;
  font-size: 20px;
  color: white;
}

.close-btn-feedback {
  cursor: pointer;
  font-size: 20px;
  color: black
}

.nav-chevron {
  cursor: pointer;
  font-size: 15px;
  color: var(--white);
}

.no-sessions-message {
  text-transform: initial !important;
  font-family: "Open Sans", serif !important;
  font-size: 11px;
}

.player-select-checkbox {
  --background: transparent;
  --background-checked: var(--white);
  --border-color-checked: none !important;
  --checkmark-color: #000000 !important;
  --checkmark-width: 2.2px;
  width: 21px;
  height: 20px;
  --border-radius: 4px;
  --border-color: var(--fourth-colour);
  --border-width: 1px;
}

.session-selector-checkbox {
  width: 22px !important;
  height: 22px !important;
}

.highcharts-scrolling {
  overflow: scroll;
}


.highcharts-scrolling::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.chart-container .highcharts-yaxis {
  fill: rgb(14, 15, 17);
}

.checkbox-checked .checkbox-inner {
  position: absolute;
  top: 9px;
  left: 13px;
  height: 11px;
  width: 5px;
}

.player-select-checkbox::part(mark) {
  background: #000000 !important;
}

.player-select-checkbox::part(container) {

  padding: 5px
}

.zone-container {
  background-color: rgba(0, 0, 0, 0.7);
}

.zone-font {
  font-size: 30px;
  font-family: "Oswald", serif !important;
}

@media (max-width: 576px) {
  .hide-xs {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .hide-sm {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .hide-md {
    display: none;
  }
}

.searchbar-left-aligned.sc-ion-searchbar-ios-h .searchbar-input.sc-ion-searchbar-ios {
  font-size: 12px;
}

.option-icon, .nav-icon {
  color: var(--fourth-colour) !important;
}

.option-icon {
  padding-right: 8px;
}

.nav-icon {
  width: 14px;
  height: 100%;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
}

.help-container {
  height: 40px;
  border-radius: 15px;
  outline: none;
  color: var(--white);
}

.help-container-background-tablet {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  outline: none;
  color: var(--white);
  background-color: white;
}

// auth pages
.auth-input-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}


.header-auth-page {
  position: absolute;
  top: 11%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.header-auth-page-focused {
  position: absolute;
  top: 11%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header-auth-page-title {
  position: absolute;
  top: 22%;
  left: 50%;
  transform: translate(-50%, -50%);
}


@media(max-height: 900px) {
  .header-auth-page-title {
    top: 18%;
  }
}



.header-auth-page-title-focused {
  position: absolute;
  top: 32%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main-content-auth-pages {
  position: absolute;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main-content-auth-pages-focused {
  position: absolute;
  top: 73%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.chart-title {
  position: absolute;
  //height: 100%;
  left: 50%;
  transform: translate(-50%, -0%);
  pointer-events: none;
  text-align: center;
  padding-top: 4px;
}

.heading-title {
  position: absolute;
  height: 100%;
  left: 50%;
  transform: translate(-50%, -0%);
  pointer-events: none;
}

.floating-heading {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -0%);
  pointer-events: none;
}

.header-icon {
  font-size: 16px;
  color: var(--white);
}

.help-container-background {
  width: 32px;
  height: 32px;
  border-radius: 15px;
  background-color: white;
}


.disable-ripple {
  --ripple-color: transparent;

  &:hover {
    background: unset !important;
  }
}

.session-calendar {
  font-size: 8px !important;
    color: white;
    font-weight: 600;
    /* padding: 2px; */
    display: flex
;
    /* padding: 2px; */
    height: 8px;
    width: 35px;
    /* margin: auto; */
    border-radius: 24px;
    margin-bottom: -11px;
    font-size: 8px;
    align-content: flex-start;
    align-items: center;
    justify-content: center;
}


.add-bulk-gameday-model {
  --width: 80vh;
}

.gameday-session-calendar {
  background-color: var(--primary-blue);
}

.gameday-session-calendar-upcoming-session {
  border: 1px solid var(--primary-blue);
  background-color: transparent;
}

.bulk-gameday-session-calendar {
  background-color: var(--azure-radiance-500);
}

.bulk-gameday-session-calendar-upcoming-session {
  border: 1px solid var(--azure-radiance-500);
  background-color: transparent;
}


.practice-session-calendar {
  background-color: var(--white);
  color: black;
}

.practice-session-calendar-upcoming-session {
  background-color: transparent;
  border: 1px solid white;
  color: black;
}

.light-panel-heading-settings-page {
  font-size: var(--font-md);
  color: var(--white);
  line-height: 22px;
}

.panel-subheading-text-settings-page {
  font-weight: 400;
  color: var(--fourth-colour);
  font-size: var(--font-md);
}

.sessions-popover {
  --background: var(--panel-dark);
}

.sorting-popover, .add-session-selection-popover {
  --background: var(--grey-700);
  --width: fit-content;
  padding: 2px;
}

.add-session-selection-popover::part(arrow) {
  opacity: 0;
}

.add-session-selection-popover {
  left: -50px;
}

.sorting-popover::part(content), .sorting-popover::part(arrow)::after {
  background-color: var(--grey-700);
}

.span-session-details {
  padding-left: 8px;
}

.ripple-parent {
  position: relative;
  overflow: hidden;
}

.modal-height-rule {
  max-height: fit-content;
  //position: absolute;
}

.modal-height-rule-pdf {
  max-height: 540px;
  position: absolute;
}

.week-title {
  color: var(--fourth-colour) !important;
  font-size: var(--font-md) !important;
  font-weight: 600 !important;
}

.player-report-text-area {
  background-color: var(--fourth-colour-opacity-01);
  border: 1px solid var(--fourth-colour-opacity-20);
  border-radius: 4px;
  color: var(--fourth-colour);
  font-size: var(--font-md);
  padding: 12px 20px;
  width: 50vw;
}


.session-calendar-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
  gap: 13px;
}


.switch-btn, .days-btn p, .month-packer-item button, .title .back, .title .forward {
  color: var(--fourth-colour) !important;
  -webkit-appearance: none;
}

.week-toolbar {
  width: 96%;
  margin-left: 2%;
}

.calendar-bulk-gameday .week-toolbar {
  width: 100%;
  margin-left: 0;
}

.bulk-gameday-calendar-checked
.uppercase {
  text-transform: uppercase;
}

.month-packer-item {
  height: 10vh !important;
}

.title {
  // height: 5vh;
  // padding-left: 4% !important;
  // padding-right: 4% !important;
}

.title {
  //border-bottom: 1px solid var(--modal-border);
}

.switch-btn {
  color: var(--white);
  font-size: var(--font-lg);
}

.days .on-selected {
  background-color: var(--white) !important;
  color: #000 !important;
  width: 20px !important;
  display: block;
  text-align: center;
  height: 20px !important;
  //padding: 3px 5px !important;
}

.startSelection::before, .startSelection::after, .endSelection::before, .endSelection::after, .between button, .endSelection button, .startSelection button {
  height: 25px !important;
}

.days .on-selected p {
  color: #000 !important;
}


button.days-btn {
  font-size: var(--font-sm) !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  padding: 0 !important;
}

button.days-btn p {
  font-size: var(--font-sm) !important;
}

button.days-btn small {
  bottom: -20px !important;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 0 auto;
  background-color: transparent;
  color: transparent;
}

.month-picker.light .month-packer-item.this-month button {
  border: none !important;
  outline: none;
}

.days-btn:disabled {
  opacity: 0.4;
}

.title .back {
  margin-left: 0 !important;
  //top: 13px !important;
  position: static !important;
  z-index: 100 !important;
  color: white;
}

.title .forward {
  color: white;
  margin-left: 0 !important;
  //top: 13px !important;
  position: static !important;
  z-index: 100 !important;
}

.notification-badge {
  font-size: 13px;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove-default-style {
  -webkit-appearance: none !important;
}

.disable-pointer-events {
  pointer-events: none;
}

.closed-player-panel {
  align-items: flex-start !important;
}

.player-panel {
  padding-left: 8px;
  padding-top: 8px
}

.textarea-wrapper {
  height: 100%
}

.pdf-average {
  background: black;
  color: white;
  text-transform: capitalize;
}

.pro-icon {
  background-color: #727DA9;
  font-size: 10px;
  padding: 0px 6px;
}

.no-scroll {
  overflow: hidden;
}


.upgrade-card-overlay {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
}

.session-type-segment-button {
  --border-radius: 15px;
  --background: var(--input-background);
  --color: var(--panel-text);
  --color-checked: var(--white) !important;
  --background-checked: var(--panel-text) !important;
  --indicator-color: transparent !important;
  --border-width: 0px;
  --indicator-color-checked: 0;
  font-size: 11px;
  font-weight: bold !important;
}


//.segment-button.active, .segment-button:hover  {
//  background-color: var(--fourth-colour-opacity-20);
//  color: #fff;
//  border-radius: 4px;
//  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Add a shadow effect */
//  z-index: 1!important;
//  //transform: translateY(0px) translateX(12px); /* Shift button slightly up and to the right */
//}

.data-over-time-segment {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: var(--grey-700);
}

.segment-button {
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
  color: #fff;
  text-align: center;

  &:hover{
    background-color: var(--button-hover);
    color: var(--white);
    border-radius: 4px;
  }

  span {
    padding: 12px 20px;
  }
}

.segment-button-calendar-page {
  padding: 4px 12px;
  display: flex;
  height: 100%;
  text-align: center;
  align-content: center;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.slider-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  z-index: 3;
}

.button-slider {
  position: absolute;
  left: 0;
  width: 0;
  height: 100%;
  top: 0;
  background-color: var(--fourth-colour-opacity-20);
  // transition: width 0.3s;
  z-index: 2;
  color: var(--fourth-colour);
  border-radius: 4px;
}


.choose-metrics-btn {
  margin: 0;
  width: 120px;
  height: 35px !important;
  --border-radius: 10px;
}

.legend-icon {
  width: 11px;
  height: 11px;
  background-color: inherit;
  border-radius: 50%;
}

.legend-name {
  font-size: var(--font-xsm);
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  //margin-left: 8px;
  //width: min-content;
  //text-overflow: ellipsis;
}


.combo-legend-container {
  gap: 4px;
  display: flex;
}

.legend-name-sm {
  font-size: var(--font-xsm);
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  //margin-left: 8px;
  //width: min-content;
  //text-overflow: ellipsis;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.calendar {
  border-radius: 5px;
  background-color: transparent !important;
  padding-top: 4px;
  overflow: scroll;
}


.calendar .switch-btn ion-icon {
  display: none;

}

ion-calendar {
  font-family: 'Barlow', sans-serif;
  font-weight: 600
}

.title {
  display: flex;
}


.calendar .days {
  // margin-bottom: 1vh !important;
  // height: 5.5vh !important;
  height: 6vh!important;
}

.calendar-bulk-gameday .days {
  height: 3.25vh !important;
  margin-bottom: 1.8vh !important;
}

.calendar .title {
  border-bottom: 0 solid var(--modal-border);
  font-min-size: 15px !important;
margin-top: 16px;
  height: 10%;
}

.calendar-bulk-gameday .title {
  margin: 0 27%;
  display: flex;
  align-items: center;
}

.calendar-bulk-gameday .title div {
  height: fit-content;
  text-wrap: nowrap;
}


.calendar .switch-btn {
  font-size: var(--font-lg) !important;
  color: white !important;
  order: 2;
  height: auto;
}

.calendar-bulk-gameday .switch-btn {
  font-size: var(--font-md) !important;
}

.calendar .forward {
  color: var(--fourth-colour) !important;
  //top: 145px !important;
  //right: 65px !important;
  height: 100%;
  order: 3;
  position: static;
}

.calendar .back {
  //top: 145px !important;
  //left: 65px !important;
  color: var(--fourth-colour) !important;
  height: 100%;
  order: 1;
  position: static;
}



.booking-calendar {
  border-radius: 5px;
  background-color: transparent !important;
  padding: 10px 0 !important;
}

.booking-calendar .days {
  margin-bottom: 1vh !important;
  height: 4.5vh !important;
}

.booking-calendar .title {
  border-bottom: 0 solid var(--modal-border);
  padding-top: 5px !important;
  font-min-size: 15px !important;
}

.booking-calendar .switch-btn {
  font-size: 15px !important;
}

.session-date-picker ion-button::part(native) {
  color: white;
  font-size: var(--font-lg);
}

.booking-calendar .forward {
  right: 25px !important;
}

.booking-calendar .back {
  left: 25px !important;
}

.metric-name {
  margin-right: 8px;
}

.picker-wrapper, .ion-overlay-wrapper {
  background: var(--grey-800) !important;
  color: var(--white) !important;
}

.upgrade-to-pro-icon {
  background-color: white;
  padding: 3px 8px;
  color: black;
  border-radius: 4px;
}

.pac-item span:last-child {
  color: black;
}

.sc-ion-alert-ios {
  color: var(--white) !important;
}

ion-modal::part(content) {
  --background: none !important;
  background: none !important;
  border-radius: 0 !important;
}

.upgrade-subscription-overly {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(23, 24, 28, 0) 0%, var(--panel-dark) 100%);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  z-index: 2;
  position: absolute;
}

a {
  color: inherit;
  text-decoration: none;
  background-color: transparent;
}

// font

.font-oswald-bold {
  font-family: Oswald, serif !important;
  font-weight: bold;
}

.font-oswald {
  font-family: Oswald, sans-serif !important;
  font-weight: normal;
}

.no-overflow-wrap-text {
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  width: 100%;
}

.no-overflow-wrap-text-auto {
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
  display: inline-block;
}


.now-wrap {
  text-wrap: nowrap;
}

// font size

.font-xsm {
  font-size: var(--font-xsm);
}

.font-xxsm {
  font-size: var(--font-xxsm);
}

.font-sm {
  font-size: var(--font-sm);
  //line-height: var(--line-height-sm);
}

.font-md {
  font-size: var(--font-md) !important;
}

.font-md-lg {
  font-size: var(--font-md-lg) !important;
}

.font-lg {
  font-size: var(--font-lg);
  line-height: 20.8px;
}


.font-xl {
  font-size: var(--font-xl);
}

.font-xxl {
  font-size: var(--font-xxl);
}

.font-xxxl {
  font-size: var(--font-xxxl);
}

.font-xxxxl {
  font-size: var(--font-xxxxl);
}

.font-xxxxxl {
  font-size: var(--font-xxxxxl);
}

.font-color-grey-400 {
  color: var(--grey-400);
}

.font-color-grey-50 {
  color: var(--grey-50);
}


.font-color-grey-300 {
  color: var(--grey-300);
}

.lh-xxxsm {
  line-height: var(--line-height-xxxsm);
}

.lh-xxsm {
  line-height: var(--line-height-xxsm);
}

.lh-xsm {
  line-height: var(--line-height-xsm);
}

.lh-sm {
  line-height: var(--line-height-sm);
}

.lh-md {
  line-height: var(--line-height-md);
}

.lh-md-lg {
  line-height: var(--line-height-md-lg);
}

.lh-lg {
  line-height: var(--line-height-lg);
}

.lh-xl {
  line-height: var(--line-height-xl);
}

.lh-xxl {
  line-height: var(--line-height-xxl);
}

.lh-xxxxl {
  line-height: var(--line-height-xxxxl);
}

.font-w-normal {
  font-weight: 600;
}

.font-w-500 {
  font-weight: 500;
}

.gap-24 {
  gap: 24px;
}

.gap-16 {
  gap: 16px;
}

div {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

//.session-metric-chart div{
//  height: 100%!important;
//}
//
//.session-metric-chart .highcharts-background {
//    height: 100%;
//  }

.font-w-light {
  font-weight: 400;
}

.font-w-bold {
  font-weight: 700;
}

.font-italic {
  font-style: italic;
}

.change-hint-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  top: 0;
}


.change-hint-text {
  background-color: var(--white);
  border-radius: 13px;
  height: 26px;
  color: #000;
  width: auto;
  vertical-align: middle;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 20px;
  font-size: 11px;
  z-index: 10;
}

//font color
.font-color-1 {
  color: var(--fourth-colour);
}

.font-color-black {
  color: black;
}

.font-color-1-on-hover {
  &:hover{
    color: var(--fourth-colour)!important;
  }
}

.font-color-white-on-hover {
  &:hover{
    color: var(--white)!important;
  }
}

.font-color-white {
  color: var(--white);
  
}

.font-color-white-opacity-02 {
  color: var(--white-opacity-02);
}

.font-light-grey {
  color: var(--light-grey);
}

.font-color-delete-color {
  color: var(--delete-color);
}

.font-color-grey-200 {
  color: var(--grey-200)
}


.cal-icon {
  -webkit-mask: url('../assets/images/svg/calendar.svg') no-repeat center;
  mask: url('../assets/images/svg/calendar.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.small-check-icon {
  -webkit-mask: url('../assets/images/svg/small-check.svg') no-repeat center;
  mask: url('../assets/images/svg/small-check.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  width: 12px;
  height: 12px;
  background: var(--ion-color-primary);
}

.arrow-left-icon {
  -webkit-mask: url('../assets/images/svg/arrow-left.svg') no-repeat center;
  mask: url('../assets/images/svg/arrow-left.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.arrow-right-icon {
  -webkit-mask: url('../assets/images/svg/arrow-right.svg') no-repeat center;
  mask: url('../assets/images/svg/arrow-right.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.arrow-player-comparison {
  background-color: white;
  width: 28px;
  height: 28px;
}

.checkmark-unselected-bulk-gameday-calendar-icon {
  -webkit-mask: url('../assets/images/svg/checkmark-unselected-bulk-gameday-calendar.svg') no-repeat center;
  mask: url('../assets/images/svg/checkmark-unselected-bulk-gameday-calendar.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  width: 8px;
  height: 8px;
  background-color: white;
}

.not-accessible-checkmark-unselected-bulk-gameday-calendar-icon {
  -webkit-mask: url('../assets/images/svg/checkmark-unselected-bulk-gameday-calendar.svg') no-repeat center;
  mask: url('../assets/images/svg/checkmark-unselected-bulk-gameday-calendar.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  width: 8px;
  height: 8px;
  background-color: rgba(255, 255, 255, 0.2);
}

.checkmark-selected-bulk-gameday-calendar {
  -webkit-mask: url('../assets/images/svg/checkmark-selected-bulk-gameday-calendar.svg') no-repeat center;
  mask: url('../assets/images/svg/checkmark-selected-bulk-gameday-calendar.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  width: 8px;
  height: 8px;
  background-color: var(--light-blue);
}

.not-accessible-checkmark-selected-bulk-gameday-calendar {
  -webkit-mask: url('../assets/images/svg/checkmark-selected-bulk-gameday-calendar.svg') no-repeat center;
  mask: url('../assets/images/svg/checkmark-selected-bulk-gameday-calendar.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  width: 8px;
  height: 8px;
  background-color: var(--light-blue-opacity-02);
}

.share-feedback-icon {
  -webkit-mask: url('../assets/images/svg/share-feedback.svg') no-repeat center;
  mask: url('../assets/images/svg/share-feedback.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.share-feedback-size {
  width: 40px;
  height: 40px;
  background-color: var(--fourth-colour);
}

.location-point-icon {
  -webkit-mask: url('../assets/images/svg/location-point.svg') no-repeat center;
  mask: url('../assets/images/svg/location-point.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.location-point-alt-icon {
  -webkit-mask: url('../assets/images/svg/location-point-altv.svg') no-repeat center;
  mask: url('../assets/images/svg/location-point-altv.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.location-pont-size {
  width: 16px;
  height: 16px;
  background-color: var(--fourth-colour);
}

.squad-icon {
  -webkit-mask: url('../assets/images/svg/squad.svg') no-repeat center;
  mask: url('../assets/images/svg/squad.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.circle-add-icon {
  -webkit-mask: url('../assets/images/svg/circle-add.svg') no-repeat center;
  mask: url('../assets/images/svg/circle-add.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.create-join-squad-icon-size {
  width: 62px;
  height: 62px;
  background: white;
}

.pencil-icon {
  -webkit-mask: url('../assets/images/svg/pencil.svg') no-repeat center;
  mask: url('../assets/images/svg/pencil.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.export-icon {
  -webkit-mask: url('../assets/images/svg/export.svg') no-repeat center;
  mask: url('../assets/images/svg/export.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.ham-burger-menu-icon {
  -webkit-mask: url('../assets/images/svg/ham-burger-menu-icon.svg') no-repeat center;
  mask: url('../assets/images/svg/ham-burger-menu-icon.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.add-squad-avatar div{
  background-color: orange;
  & img{
    transform: scale(1.5);
  }
}

.camera-icon {
  -webkit-mask: url('../assets/images/svg/camera-solid.svg') no-repeat center;
  mask: url('../assets/images/svg/camera-solid.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  background-color: var(--white);
  width: 16px;
  height: 16px;
}

.invite-icon {
  -webkit-mask: url('../assets/images/svg/invite.svg') no-repeat center;
  mask: url('../assets/images/svg/invite.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.invite-icon-size {
  width: 16px;
  height: 16px;
  background-color: white;
}

.invite-icon-size-settings {
  width: 14px;
  height: 14px;
  background-color: white;
}

.chevron-right-icon {
  -webkit-mask: url('../assets/images/svg/chevron-right.svg') no-repeat center;
  mask: url('../assets/images/svg/chevron-right.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.chevron-right-bold-icon {
  -webkit-mask: url('../assets/images/svg/chevron-right-bold.svg') no-repeat center;
  mask: url('../assets/images/svg/chevron-right-bold.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}


.chevron-left-icon {
  -webkit-mask: url('../assets/images/svg/chevron-left.svg') no-repeat center;
  mask: url('../assets/images/svg/chevron-left.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}


.ellipse-icon {
  -webkit-mask: url('../assets/images/svg/ellipse.svg') no-repeat center;
  mask: url('../assets/images/svg/ellipse.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.pencil-icon {
  -webkit-mask: url('../assets/images/svg/pencil.svg') no-repeat center;
  mask: url('../assets/images/svg/pencil.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.pencil-icon-container {
  width: 12px;
  height: 12px;
  background-color: var(--fourth-colour);
}

.sort-icon {
  -webkit-mask: url('../assets/images/svg/sort.svg') no-repeat center;
  mask: url('../assets/images/svg/sort.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.sort-icon-container {
  width: 12px;
  height: 12px;
  background-color: var(--fourth-colour);
}

.analysis-eye-icon {
  color: var(--fourth-colour);
  font-size: var(--font-xsm);
  line-height: 80%;
}

.ham-burger-menu-analysis {
  height: 12px;
  width: 25px;
  background-color: var(--fourth-colour);
  &:hover {
    background-color: var(--white);
  }
}

.choose-metric-button {
  --padding-top: 17.5px;
  --padding-bottom: 17.5px;
  font-size: var(--font-md) !important;
  font-weight: 600 !important;
  --padding-start: 24px;
  --padding-end: 24px;
}

.light-blue-button {
  background-color: var(--pdf--combo-chart-colour-set-1-1);
  --color: white;
  --padding-start: 24px;
  --padding-end: 24px;
  --padding-top: 16px;
  --padding-bottom: 16px;
  border-radius: 4px;
  font-size: var(--font-md) !important;
}

.grey-700-button {
  --background: var(--grey-700);
  --color: var(--grey-400);
  --padding-start: 24px;
  --padding-end: 24px;
  --padding-top: 16px;
  --padding-bottom: 16px;
  border-radius: 4px;
  font-size: var(--font-md) !important;
}

.on-off-toggle {
  --background: red;
  --background-checked: var(--light-green);
  --handle-background: var(--delete-color);
  /* width: 48px; */
  height: 24px;
  padding-top: 3px;
  padding-left: 0;
  --handle-spacing: 6px;
}

.on-off-toggle[checked] {
  --background: green; /* Background color for ON state */
}

.on-off-toggle::part(handle) {
  background: white;
  height: 18px;
  width: 18px;
}

.on-off-toggle::part(track) {

}


@media (max-width: 1100px) {
  .on-off-toggle {
    padding-left: 0;
    --handle-spacing: 2px;
  }

  .on-off-toggle::part(handle) {
    height: 13px;
    width: 13px;
  }
}

.divider {
  border-top: 1px solid var(--grey-700);
  margin: 24px 0;
}

.pitch-switch-icon {
  width: 18px;
  height: 18px;
  background-color: var(--fourth-colour);
}

.pitch-switch-icon:hover {
  background-color: var(--white);
}

.search-bar {
  //height: 100% !important;
  background-color: var(--fourth-colour-opacity-003);
  color: var(--grey-400);
  --placeholder-color: rgb(255 250 250);
  --icon-color: var(--grey-400);
  --clear-button-color: var(--fourth-colour);
  border: 1px solid var(--fourth-colour-opacity-01);
  --border-radius: 4px;
  margin-top: 9px;
  border-radius: 4px;

  div {
    display: flex !important;
    align-content: center !important;
    align-items: center !important;
    justify-content: flex-end !important;
    flex-direction: row-reverse !important;
    height: 100% !important;
    background-color: transparent !important;
    padding: 11px 13px !important;
  }

  input {
    height: 100% !important;
    background-color: transparent !important;
    --box-shadow: none !important;
    font-size: var(--font-md) !important;
    padding: 0 10px !important;
    font-weight: 500 !important;
    color: var(--grey-400);
  }

  ion-icon {
    width: 22px !important;
    height: 23px !important;
    position: static !important;
    display: flex;
  }
}

.search-icon {
  -webkit-mask: url('../assets/images/svg/search.svg') no-repeat center;
  mask: url('../assets/images/svg/search.svg') no-repeat center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  background-color: var(--grey-300)!important;
  width: 18px;
  height: 18px;
}

.search-bar-analysis-group {
  --placeholder-color: rgb(255 250 250);

  ion-icon {
    width: 19.5px !important;
    height: 20px !important;
  }
}


.grey-button {
  --background: var(--grey-700);
  color: var(--white);
}

.button-extra-padding {
  --padding-top: 21px !important;
  --padding-bottom: 21px !important;
  //--padding-start: 14px !important;
  //--padding-end: 14px !important;
}

.button-extra-extra-padding {
  --padding-top: 21px !important;
  --padding-bottom: 21px !important;
  --padding-start: 22px !important;
  --padding-end: 22px !important;
}

.button-extra-extra-extra-padding {
  --padding-top: 18px !important;
  --padding-bottom: 18px !important;
  --padding-start: 42px !important;
  --padding-end: 42px !important;
}

.default-page-margin {
  margin: 0 24px;
}

.y-axis-label {
  font-size: var(--font-xsm);
}

.player-select-checkbox-sorting {
  opacity: 0;
  width: 0;
  height: 0;
}


.analysis-page-content-container {
  gap: 1.5%;
}

.mat-drawer.mat-drawer-side {
  background-color: transparent;
}

.sync-reminder-panel {
  border-radius: 4px;
  height: 100%;
  padding: 5px 6px;
  flex: 1;
  margin-left: 24px;
}

.send-reminder-btn {
  color: #DA5C5C;
  --background: var(--grey-800) !important;
  --background-hover: var(--grey-800);
  --background-focused: var(--grey-800);
  --background-activated: var(--grey-800);
  margin: 0;
  --padding-top: 24px;
  --padding-bottom: 24px;
}

.reminder-panel-btn {
  height: fit-content !important;
  margin-right: 6px;
}

.reminder-panel-btn:last-child {
  margin-right: 0;
}

.chart-row {
  flex: 1
}

.squad-page-content-container {
  gap: 24px
}

//ion-col {
//  display: flex;
//  align-items: center;
//}

.player-container-closed {
  width: 5%;
  max-width: 40px;
  transition: all 0.5s ease-in-out;
}

.player-container-opened {
  width: 25%;
  max-width: 311px;
  transition: all 0.5s ease-in-out;
}

.split::before {
  content: "";
  position: absolute;
  /* top: 50%; */
  /* bottom: 50%; */
  left: 0;
  right: 0;
  border-left: 2px solid var(--grey-700);
  z-index: 1;
  height: 72%;
  margin-top: 10%;
  pointer-events: none;
}

.player-component-container, .player-component-container-no-shadow {
  position: relative;
  z-index: 1;
}

.close-icon {
  font-size: var(--font-lg);
}

.list-container {
  //padding-top: 12px;
  padding: 32px;
  height: 100% !important;
  border: 2px solid var(--grey-700);
  border-top: 0;
  overflow-y: auto !important;
}

.list-container-comparison-selector {
  //padding-top: 12px;
  padding: 32px;
  height: 40vh;
  min-height: 40vh;
  border: 2px solid var(--grey-700);
  border-top: 0;
  overflow-y: auto !important;
}


.player-component-container:before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  height: 140px;
  pointer-events: none;
  z-index: 4;
  background-image: linear-gradient(180deg, rgba(23, 24, 28, 0) 0, var(--panel-dark));
}

.sync-reminder-panel-container {
  height: fit-content;
}

.sync-reminder-panel-button {
  height: 100%;
  min-height: 41px;
}

.default-analysis-page-padding {
  margin-top: 16px;
  margin-bottom: 16px;
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.9);
}

.metric-range-user-matchday-performance {
  --knob-background: var(--panel-dark);
  --knob-size: 15px;
  --bar-height: 6px;
  --height: 6px;
  pointer-events: none;
}


//home page
.metric-range {
  --knob-size: 0px;
  --bar-height: 4px;
  --height: 4px;
  pointer-events: none;
}


.metric-range::part(bar-active) {
  border: 1px solid var(--primary-blue);
  background: var(--primary-blue-opacity-low)
}

.metric-range::part(bar) {
  background: var(--grey-800-opacity-low);
  border: 1px solid var(--grey-800);
}

.metric-range-user-matchday-performance-both-empty::part(bar) {
  background: var(--grey-800-opacity-low) !important;
  border: 1px solid var(--grey-800) !important;
}


.metric-range-user-matchday-performance::part(bar-active) {
  border: 1px solid var(--white);
  background: var(--metric-1st-half-1);
}

.metric-range-user-matchday-performance::part(knob) {
  width: 0;
  border-radius: 0 !important;
  padding: 0 6px;
}

.player-report-buttons {
  --padding-top: 13px;
  --padding-bottom: 13px;
  --padding-start: 20px;
  --padding-end: 20px;

}


.metric-range-user-matchday-performance::part(bar) {
  border: 1px solid var(--primary-blue);
  background: var(--primary-blue-opacity-low);
}

.welcome-page-image {
  background-image: url('../assets/images/start-screen-bg-v2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.overflow-auto-modal{
  --overflow: visible!important;
}


.black-friday-popup-gradient::part(content){
  border-radius: 4px!important;
  border: 1px solid rgba(175, 175, 175, 0.2);
}

.black-friday-promo-content-description{
  background-image: url('../assets/images/black-friday-gradient.png'); /* Image layer */
}


.welcome-page-image::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 110%;
  opacity: 0.97;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.91) 30%, rgba(0, 0, 0, 0.5) 40%, rgb(38 35 35 / 40%) 80%, rgb(40 39 39 / 0%) 100%);
}

.w-min-content {
  width: min-content;
}

.blur-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  z-index: 0;
}

// button width height

.button-h-37-w-112 {
  height: 37px;
  width: 112px;
}

.legend-name-combo {
  width: 50px;
}

.label-content {
  gap: 5px;
}

// padding

//used for segment buttons
.segment-button-pv-15-ph-23 span {
  padding: 15px 23px;
}

.pl-40 {
  padding-left: 40px;
}

.pb-71 {
  padding-bottom: 71px;
}

ion-calendar-month {
  // min-height: 40vh;
}

ion-calendar-month-picker > div:first-child {
  min-height: 45.5vh;
  margin: 0 !important;
}

.calendar-bulk-gameday ion-calendar-month {
  height: 25vh;
  min-height: 0;
}

.player-comparison-item {
  margin: 10px 0;
}

//used mostly for the buttons
.ion-pv-16-ph-24 {
  --padding-top: 16px;
  --padding-bottom: 16px;
  --padding-start: 24px;
  --padding-end: 24px;
  height: fit-content;
  width: fit-content;
  margin: 0;
}

.ion-pv-22-ph-66 {
  height: fit-content;
  width: fit-content;
  margin: 0;
  --padding-top: 22px;
  --padding-bottom: 22px;
  --padding-left: 66px;
  --padding-right: 66px;
}

.ion-pv-10-ph-16 {
  --padding-top: 12px;
  --padding-bottom: 12px;
  --padding-start: 16px;
  --padding-end: 16px;
  height: fit-content;
  width: fit-content;
  margin: 0;
}

.pv-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.ph-0 {
  padding-right: 0;
  padding-left: 0;
}

.pt-0 {
  padding-top: 0 !important;
}

.pl-0 {
  padding-left: 0;
}

.pr-0 {
  padding-right: 0;
}

.pt-1 {
  padding-top: 1px;
}

.pt-2 {
  padding-top: 2px;
}

.pt-3 {
  padding-top: 3px !important;
}

.pv-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.ph-4 {
  padding-right: 4px;
  padding-left: 4px;
}

.pt-4 {
  padding-top: 4px !important;
}

.pl-4 {
  padding-left: 4px;
}

.pr-4 {
  padding-right: 4px;
}

.pt-5 {
  padding-top: 5px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pt-10 {
  padding-top: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pt-40 {
  padding-top: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

.p-20 {
  padding: 20px;
}

.pv-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.pt-20 {
  padding-top: 20px !important;
}

.pb-20 {
  padding-bottom: 20px;
}

.ph-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.p-24 {
  padding: 24px;
}

.pv-24 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.pl-24 {
  padding-left: 24px;
}

.pr-24 {
  padding-right: 24px;
}

.pt-24 {
  padding-top: 24px !important;
}

.pb-24 {
  padding-bottom: 24px;
}

.ph-24 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.ph-48 {
  padding-right: 48px !important;
  padding-left: 48px !important;
}


.pt-48 {
  padding-top: 48px !important;
}

.pt-72 {
  padding-top: 72px;
}

.p-32 {
  padding: 32px;
}

.pv-32 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.pl-32{
  padding-left: 32px;
}

.pb-32 {
  padding-bottom: 32px;
}

.pt-32 {
  padding-top: 32px !important;
}

.ph-32 {
  padding-left: 32px;
  padding-right: 32px;
}

.pb-6 {
  padding-bottom: 6px;
}

.pt-6 {
  padding-top: 6px;
}

.pl-6 {
  padding-left: 6px;
}

.pr-6 {
  padding-right: 6px;
}

.pv-6 {
  padding-top: 6px;
  padding-bottom: 6px;
}

.ph-6 {
  padding-left: 6px;
  padding-right: 6px;
}

.pb-8 {
  padding-bottom: 8px;
}

.pt-8 {
  padding-top: 8px !important;
}

.pl-8 {
  padding-left: 8px;
}

.pr-8 {
  padding-right: 8px;
}

.pv-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.ph-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.pr-10 {
  padding-right: 10px;
}

.p-12 {
  padding: 12px;
}

.pv-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.pb-12 {
  padding-bottom: 12px;
}

.pt-12 {
  padding-top: 12px;
}

.ph-12 {
  padding-left: 12px;
  padding-right: 12px;
}


.pt-16 {
  padding-top: 16px;
}

.pr-16 {
  padding-right: 16px;
}

.pl-16 {
  padding-left: 16px !important;
}

.pb-16 {
  padding-bottom: 16px !important;
}

.pv-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.ph-16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}


.pb-32 {
  padding-bottom: 32px !important;
}


// heights

.h-51-px {
  height: 51px;
}


.h-5 {
  height: 5%;
}

.h-10 {
  height: 10%;
}

.h-15 {
  height: 15%;
}

.h-20 {
  height: 20%;
}

.h-25 {
  height: 25%;
}

.h-30 {
  height: 30%;
}

.h-35 {
  height: 35%;
}

.h-40 {
  height: 40%;
}

.h-45 {
  height: 45%;
}

.h-50 {
  height: 50%;
}

.h-55 {
  height: 55%;
}

.h-60 {
  height: 60%;
}

.h-65 {
  height: 65%;
}

.h-70 {
  height: 70%;
}

.h-75 {
  height: 75%;
}

.h-80 {
  height: 80%;
}

.h-85 {
  height: 85%;
}

.h-90 {
  height: 90%;
}

.h-95 {
  height: 95%;
}


// animations


@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}


@keyframes slide-in-blurred-top {
  0% {
    transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    transform-origin: 50% 0%;
    filter: blur(40px);
    opacity: 0;
  }

  100% {
    transform: translateY(0) scaleY(1) scaleX(1);
    transform-origin: 50% 50%;
    opacity: 1;
  }
}


.fade-in-top {
  -webkit-animation: fade-in-top 0.95s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.25s both;
  animation: fade-in-top 0.95s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.25s both;
}

.fade-in-bottom {
  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.65s both;
  animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.65s both;
}

.slide-in-blurred-top {
  animation: slide-in-blurred-top 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

.card {
  padding: 24px;
  border-radius: 8px;
  background-color: var(--panel-dark);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: white;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.session-select-desktop-previous-sessions-button-container {
  border-bottom: 2px solid transparent;
  cursor: pointer;
  &:hover {
    
    .session-select-desktop-previous-sessions-button{
      color: white;
      font-weight: 600;
    }



  }
}

.session-select-desktop-previous-sessions-button-container-selected {
  border-bottom: 2px solid white;
}

.session-select-desktop-previous-sessions-button {
  color: var(--grey-400);
  font-weight: 400;
  font-size: var(--font-sm);

  &-selected {
     font-weight: 600;
     color: white;
  }

  

}


//width
@media (min-width: 1200px) {
  button.days-btn {
    padding: 0 !important;
  }
  .alert-title.sc-ion-alert-md, .alert-radio-label.sc-ion-alert-md, .alert-head .sc-ion-alert-md {
    font-size: var(--font-md);
  }

  .alert-message.sc-ion-alert-md {
    font-size: var(--font-sm);
  }

  .font-lg {
    line-height: 22.8px;
  }

  .font-md {
    line-height: 19.2px;
  }

  .font-sm {
    line-height: 14.8px;
  }

  .player-select-checkbox {
    width: 23.5px;
    height: 24.2px;
  }

  .metric-range {
    --knob-size: 0px;
    --bar-height: 4px;
    --height: 4px;
    pointer-events: none;
  }

  .calendar {
    height: 50vh!important;
  }

 


  .legend-name-combo {
    width: 80px;
  }

  .option-icon {
    font-size: var(--font-lg);
  }

  .exclamation-mark-icon-size {
    width: 39px;
    height: 38px;
  }

  .session-selector-ion-segment {
    width: 80% !important;
  }
}

@media (min-width: 1400px) {
  .user-tutorial-modal, .add-squad-modal {
    --width: 50%;
    --height: fit-content;
  }

  .legend-name-combo {
    width: 100px;
  }

  .legend-custom-combo-icon {
    width: 14px;
    height: 14px;
  }

  .legend-icon {
    width: 16px;
    height: 16px;
    background-color: inherit;
    border-radius: 50%;
  }

  .edit-player-modal, .invite-code-modal, .add-session-modal {
    --width: 40%;
    --height: fit-content;
  }

  .player-report-selector-modal {
    --width: fit-content;
    --height: fit-content;
  }

  .player-list-container {
    width: 40vw;
  }

  .comparison-selector-modal {
    --width: 60%;
  }

  .select-metric-list-container {
    width: 40vw;
  }

  .session-selector-container {
    width: 60px;
  }

  .days button {
    padding: 0 !important;
  }

  .result-explainer-modal {
    --width: 40vw;
  }

  .enter-result-modal {
    --width: 50vw;
  }

  .sort-icon-container {
    width: 13px;
    height: 13px;
  }

  .analysis-eye-icon {
    color: var(--fourth-colour);
    line-height: 80%;
    font-size: 10px;
  }

  .chart-title-text, .chart-title-text-gameday {
    width: 100% !important;
  }

  .combo-chart-labels-container {
    width: 38%;
  }
  .lh-30px {
    line-height: 30px;
  }

  .session-selector-ion-segment {
    width: 80% !important;
  }

}


@media (max-width: 1200px) {
  .secondary-panel-button {
    --background-hover: white;
    //max-width: 150px;
  }

  .player-comparison-item {
    margin: 30px 0;
  }
}

//ion-input{
//  pointer-events: none;
//}


@media (min-width: 1201px) and (max-width: 1600px) {
  .choose-comparison-ion-segment {
    width: 85% !important;
  }
}

.pointer-highcharts:not(:empty) p:empty {
  display: none;
}

.donut {
  width: 10px;
  height: 10px;
  position: relative;
  border-radius: 50%;
}

.donut:before {
  content: '';
  width: 7px;
  height: 7px;
  display: block;
  position: absolute;
  transform: translate(50%, 50%);
  border-radius: 50%;
  background-color: #383838;
}

.ordinal {
  font-size: var(--font-xsm);
}

.donut--combo-chart-colour-set-1-1:before {
  background-color: var(--combo-chart-colour-set-1-1);
}

.donut--combo-chart-colour-set-1-2:before {
  background-color: var(--combo-chart-colour-set-1-2);
}

.donut--combo-chart-colour-set-1-0:before {
}


.donut--combo-chart-colour-set-2-1:before {
  background-color: var(--combo-chart-colour-set-2-1);
}

.donut--combo-chart-colour-set-2-2:before {
  background-color: var(--combo-chart-colour-set-2-2);
}

.donut--combo-chart-colour-set-2-0:before {
}

.item-interactive {
  --highlight-background: var(--grey-700);
}

.alert-radio-group.sc-ion-alert-md, .alert-checkbox-group.sc-ion-alert-md {
  border-width: 0 !important;
}

.session-select-area-desktop {
  background-color: var(--grey-900);
  padding-left: 32px;
  border-radius: 4px;
}


.highcharts-tooltip {
  z-index: 9998;
}

.lh-80 {
  line-height: 80%;
}

.lh-85 {
  line-height: 85%;
}

.lh-90 {
  line-height: 90%;
}

.lh-95 {
  line-height: 95%;
}

.highcharts-tooltip span {
  //background-color: #383838;
  opacity: 1;
  z-index: 9999 !important;
}

.score-input input {
  padding: 2px !important;
}

.list-action-icon {
  width: 12px;
  height: 14px;

  &-delete {
    height: 24px;
    width: 22px;
  }
}

.highcharts-tooltip span {
  //background-color: #383838;
  opacity: 1;
  z-index: 9999 !important;
}

.score-input input {
  padding: 2px !important;
}

.calendar-button-desktop {
  font-size: var(--font-md) !important;
  height: 100% !important;
  width: fit-content;
}

.calendar-button-tablet {
  --padding-top: 17px;
  --padding-bottom: 17px;
  --padding-start: 22px;
  --padding-end: 21px;
  //max-width: 190px;
  --border-radius: 6px;
  font-size: var(--font-md) !important;
}

.chevron-down-button {
  width: 8px;
  height: 8px;
  background-color: black;
}

.icon-container {
  width: 14px;
  height: 14px;
}

.icon-container-large {
  width: 28px;
  height: 32px;
}

.session-details {
  border-radius: 6px;
  font-size: 12px;
  font-weight: bold;
  color: var(--panel-text);
  cursor: pointer;
  padding: 6px 8px;
}

.chart-avatar-xaxis-container {
  width: 38px;

  &-image {
    width: 38px;
    height: 38px;
  }
}

.secondary-button {
  --background: var(--grey-700);
  color: var(--white);
}


// class names for legend icons
.metric-total-2-combo {
  background-color: var(--metric-total-2-combo);
}

.metric-1st-half-2-combo {
  background-color: var(--metric-1st-half-2-combo);
}

.metric-2nd-half-2-combo {
  background-color: var(--metric-2nd-half-2-combo);
}

.metric-total-1-combo-border {
  background-color: var(--metric-total-1-combo-border);
}

.metric-1st-half-1-combo-border {
  background-color: var(--metric-1st-half-1-combo-border);
}

.metric-2nd-half-1-combo-border {
  background-color: var(--metric-2nd-half-1-combo-border);
}

.metric-total-2-combo-border {
  background-color: var(--metric-total-2-combo-border);
}

.metric-total-2-border {
  background-color: var(--metric-total-2-border);
}

.metric-1st-half-2-combo-border {
  background-color: var(--metric-1st-half-2-combo-border);
}

.metric-2nd-half-2-combo-border {
  background-color: var(--metric-2nd-half-2-combo-border);
}

.combo-chart-colour-set-1-1 {
  background-color: var(--combo-chart-colour-set-1-1);
}

.combo-chart-colour-set-1-2 {
  background-color: var(--combo-chart-colour-set-1-2);
}

.combo-chart-colour-set-1-0 {
  background-color: var(--combo-chart-colour-set-1-0);
}

.combo-chart-colour-set-1-1-03 {
  background-color: var(--combo-chart-colour-set-1-1-03);
}

.combo-chart-colour-set-1-2-03 {
  background-color: var(--combo-chart-colour-set-1-2-03);
}

.combo-chart-colour-set-1-0-03 {
  background-color: var(--combo-chart-colour-set-1-0-03);
}

.combo-chart-colour-set-1-0-2 {
  background-color: var(--combo-chart-colour-set-1-0-2);
}

.combo-chart-colour-set-2-0-2 {
  background-color: var(--combo-chart-colour-set-2-0-2);
}


.combo-chart-colour-set-2-1 {
  background-color: var(--combo-chart-colour-set-2-1);
}

.combo-chart-colour-set-2-2 {
  background-color: var(--combo-chart-colour-set-2-2);
}

.combo-chart-colour-set-2-0 {
  background-color: var(--combo-chart-colour-set-2-0);
}

.combo-chart-colour-set-2-1-03 {
  background-color: var(--combo-chart-colour-set-2-1-03);
}

.combo-chart-colour-set-2-2-03 {
  background-color: var(--combo-chart-colour-set-2-2-03);
}

.combo-chart-colour-set-2-0-03 {
  background-color: var(--combo-chart-colour-set-2-0-03);
}

.metric-total-1 {
  background-color: var(--metric-total-1);
}

.metric-1st-half-1 {
  background-color: var(--metric-1st-half-1);
}

.metric-2nd-half-1 {
  background-color: var(--metric-2nd-half-1);
}

.metric-total-2 {
  background-color: var(--metric-total-2);
}

.metric-2nd-half-2 {
  background-color: var(--metric-2nd-half-2);
}

.metric-1st-half-2 {
  background-color: var(--metric-1st-half-2);
}

.metric-total-1-border {
  background-color: var(--metric-total-1-border);
}

.metric-1st-half-1-border {
  background-color: var(--metric-1st-half-1-border);
}

.metric-2nd-half-1-border {
  background-color: var(--metric-2nd-half-1-border);
}






@media (min-width: 1200px) {

  .days .on-selected {
    width: 25px !important;
    height: 25px !important;
  }


  .endSelection .days-btn{
    width: 25px !important;
    height: 25px !important;
  }




  .icon-container {
    width: 18px;
    height: 18px;
  }
  .session-details-container {
    //width: 50%;
  }

  .chart-avatar-xaxis-container {
    width: 45px;

    &-image {
      width: 45px;
      height: 45px;
    }
  }

  .search-bar div {
    padding: 15px 13px !important;
  }
  .legend-custom-combo-icon {
    width: 16px;
    height: 16px;
  }
}

.searchbar-small {
  div {
    padding: 4.5px 7px !important;
  }

  ion-icon {
    width: 18px !important;
    height: 24px !important;
  }
}

.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.current-session-details-column {
  width: 60%;
  padding-right: 24px;
}


@media (max-height: 1200px) {
  .settings-list {
    overflow: auto;
    padding-bottom: 140px;
  }
}


@media (max-height: 800px) {
  .players-container {
    height: 162px;
    overflow: auto;
  }

  .calendar-bulk-gameday .days {
    margin-bottom: 2.7vh !important;
  }
}

@media (max-height: 600px) {
  .players-container {
    height: 122px;
    overflow: auto;
  }
}


// column for sessions on the home
@media (min-width: 700px) and (max-width: 719px) {
  .current-session-details-column {
    width: 61%;
  }
}

@media (min-width: 720px) and (max-width: 739px) {
  .current-session-details-column {
    width: 62%;
  }
}

@media (min-width: 740px) and (max-width: 759px) {
  .current-session-details-column {
    width: 63%;
  }
}

@media (min-width: 760px) and (max-width: 779px) {
  .current-session-details-column {
    width: 64%;
  }
}

@media (min-width: 780px) and (max-width: 799px) {
  .current-session-details-column {
    width: 65%;
  }
}

@media (min-width: 800px) and (max-width: 819px) {
  .current-session-details-column {
    width: 66%;
  }
}

@media (min-width: 820px) and (max-width: 839px) {
  .current-session-details-column {
    width: 67%;
  }
}

@media (min-width: 840px) and (max-width: 859px) {
  .current-session-details-column {
    width: 68%;
  }
}

@media (min-width: 860px) and (max-width: 879px) {
  .current-session-details-column {
    width: 69%;
  }
}

@media (min-width: 880px) and (max-width: 899px) {
  .current-session-details-column {
    width: 70%;
  }
}

@media (min-width: 900px) and (max-width: 919px) {
  .current-session-details-column {
    width: 71%;
  }
}

@media (min-width: 920px) and (max-width: 939px) {
  .current-session-details-column {
    width: 72%;
  }
}

@media (min-width: 940px) and (max-width: 959px) {
  .current-session-details-column {
    width: 73%;
  }
}

@media (min-width: 960px) and (max-width: 979px) {
  .current-session-details-column {
    width: 74%;
  }
}

@media (min-width: 980px) and (max-width: 999px) {
  .current-session-details-column {
    width: 75%;
  }
}

@media (min-width: 1000px) and (max-width: 1019px) {
  .current-session-details-column {
    width: 76%;
  }
}

@media (min-width: 1020px) and (max-width: 1039px) {
  .current-session-details-column {
    width: 77%;
  }
}

@media (min-width: 1040px) {
  .current-session-details-column {
    width: 77%;
  }
}

@media (min-width: 1400px) {
  .current-session-details-column {
    width: 78%;
  }
}

@media (min-width: 1800px) {
  .current-session-details-column {
    width: 82%;
  }
}

// height media queires
@media (max-height: 1000px) {
  .merge-sessions-modal::part(content) {
    //height: 100%
  }
}

.blur-modal {
  --background: transparent;
  --backdrop-opacity: 0 !important; // Set to 0 to use our custom backdrop
  
  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(0 0 0 / 20%);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    z-index: -1;
  }

  &::part(background) {
    background: rgba(26, 32, 46, 0.7) !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3) !important;
  }
}

// Add this near the top of the file with other global styles
input, textarea, [contenteditable] {
  caret-color: var(--fourth-colour);
}

// Custom styles for ion-select
.select-custom {
  &:hover {
    color: var(--ion-color-white) !important;
    font-weight: bold !important;
  }

  &.select-expanded,
  &.select-active {
    color: var(--ion-color-white) !important;
    font-weight: bold !important;
  }
}

// Style for the select options when opened
ion-select-option {
  &:hover {
    color: var(--ion-color-white) !important;
    font-weight: bold !important;
  }
}
