// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import "~bootstrap/dist/css/bootstrap.css";

.zone-update-toast {
    --background: var(--ion-color-dark);
    --color: white;
    --border-radius: 8px;
    --button-color: white;
}

.loading-icon {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    100% { transform: rotate(360deg); }
}

.loading-modal-class {
  --backdrop-opacity: 0.5;
  
  .modal-wrapper {
    background: transparent;
  }
}
