// Colors for data series and points.
$colors: var(--primary-blue) var(--white) #9b6fff;

// Chart background, point stroke for markers and columns etc
$background-color: transparent !default;

// Neutral colors, grayscale by default. The default colors are defined by mixing the background-color with neutral, with a weight corresponding to the number in the name.
$neutral-color-100: var(--white) !default; // Strong text.
$neutral-color-80: var(--white) !default; // Main text and some strokes.
$neutral-color-60: var(--panel-text) !default; // Axis labels, axis title, connector fallback.
$neutral-color-40: var(--panel-text) !default; // Credits text, export menu stroke.
$neutral-color-20: var(--panel-text) !default; // Disabled texts, button strokes, crosshair etc.
$neutral-color-10: var(--panel-text) !default; // Grid lines etc.
$neutral-color-5: var(--panel-text) !default; // Minor grid lines etc.
$neutral-color-3: var(--panel-text) !default; // Tooltip backgroud, button fills, map null points.

//.highcharts-title, .highcharts-subtitle, .highcharts-axis-title {
//
//  font-weight: bold;
//  font-family: Play, sans-serif;
//  font-size: 12px !important;
//}
//
//.highcharts-tooltip-box {
//  fill-opacity: 0.6 !important;
//  stroke-width: 0 !important;
//  fill: var(--panel-dark) !important;
//}
//
//.highcharts-tooltip {
//  color: var(--white) !important;
//}
//
.highcharts-plot-line {
  stroke: var(--) !important;
  stroke-width: 0.8px !important;
  stroke-linecap: round !important;
  stroke-dasharray: 5 !important;
}

.highcharts-plot-line-label {
  fill: var(--average-line) !important;
  background-color: var(--average-line);
  padding: 2px 4px;
  color: #12111A;
  font-size: var(--font-xxsm) !important;
  margin-left: -12px !important;
  font-weight: 700;
}
//
//.highcharts-data-label {
//  font-size: 12px !important;
//  font-family: Play, sans-serif;
//}
//
//.highcharts-data-label text, text.highcharts-data-label {
//  fill: white !important;
//}
////
//.pdf-chart-data-label text{
//  fill: black !important;
//  font-weight: bold !important;
//  font-size: var(--font-lg)!important;
//}
////
////.highcharts-grid-line {
////  fill: none;
////  stroke: var(--panel-text);
////  opacity: 0.3;
////}
////
////.highcharts-axis-line {
////  stroke: transparent !important;
////}
////
////.highcharts-text-outline {
////  stroke-width: 0 !important;
////}
////
////.highcharts-axis-title {
////  fill: var(--panel-text) !important;
////}
////
////
////.pdf-chart-yaxis .highcharts-axis-title {
////  //fill: var(--light-text) !important;
////}


 .rect.highcharts-point {
  rx: 6; /* Set the horizontal border radius to 0 */
  ry: 6; /* Set the vertical border radius to 0 */
}

.highcharts-container {
  position: relative;
  overflow: auto;
  width: 100%;
  height: 100%;
  text-align: left;
  line-height: normal;
  z-index: 0;
  -webkit-tap-highlight-color: transparent;
  font-family: lucida grande,lucida sans unicode,Arial,Helvetica,sans-serif;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  touch-action: manipulation;
  outline: none;
}


.highcharts-root{
  display: block;
}

